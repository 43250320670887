/* eslint-disable react-hooks/exhaustive-deps */

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  RadioProps,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BookingApi from "../api/BookingApi";
import BookingParantmodal from "./BookingParantmodal";
import { BookingSubTypes } from "./BookingSubTypes";
import { BookingType } from "../CommonFiles/BookingType";
import BowlingMachine from "./3756959.webp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../CommonComponents/Colors";
import CustomTextField from "../CommonComponents/CustomTextField";
import DateUtils from "../Utils/DateUtils";
import LockIcon from "@mui/icons-material/Lock";
import ModalComponent from "../CommonComponents/CustomDateCalender/ModalComponent";
import OffersApi from "../api/OffersApi";
import PaymentApi from "../api/PaymentApi";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import TodayIcon from "@mui/icons-material/Today";
import UserApi from "../api/UserApi";
import assets from "../assets";
import backgroundimage from "./IMG_1517.jpg";
import bannerBG from "./badminton-type.jpg";
import bannerBG2 from "../assets/ServiceImages/cricket_banner.jpg";
import bannerBG3 from "../assets/ServiceImages/playstation_banner.png";
import bannerBG4 from "../assets/ServiceImages/turf_banner.jpg";
import boardImage from "../BookingService/board.svg";
import routes from "../routes/routes";
import styled1 from "@emotion/styled";
import turfImage from "../BookingService/cricket_stumb.png";

const BannerImage = styled1.img`
  @media (min-width: 300px) {
   display: none;
  }
  @media (min-width: 1200px) {
    display: block;
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin: 0px;
  }
`;

const LogoImage = styled1.img`
  @media (min-width: 300px) {
   width: 180px;
  }
  @media (min-width: 1200px) {
    width: 300px;
  }
`;

const { "card icons.png": cardicons, "Icon.png": crediticon } = assets;

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  border: "1px solid #15B5FC",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#15B5FC",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#15B5FC",
  },
});

const BoardImage = styled1.img`
@media (min-width: 300px) {
  width: 20px;
}
  @media (min-width: 1200px) {
  width: 25px;
}
`;
const CricketNetImage = styled1.img`
@media (min-width: 300px) {
  width: 20px;
}
  @media (min-width: 1200px) {
  width: 25px;
}
`;
const BowlingMachineImage = styled1.img`
@media (min-width: 300px) {
  width: 20px;
}
  @media (min-width: 1200px) {
  width: 25px;
}
`;

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
export default function PaymentBooking() {
  const initialPaymentMethod = "female";
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(initialPaymentMethod);
  const [applyPromo, setApplyPromo] = useState("");
  const [applyValue, setApplyValue] = useState(0);
  const [payValue, setPayValue] = useState(0);

  const handlePaymentMethodChange = (event: any) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const user = localStorage.getItem("user");
  const userData = user && JSON.parse(user);

  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    userData.phone === 0 ? "" : userData.phone ?? ""
  );
  const [email, setEmail] = React.useState(userData.email ?? "");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [finalDownloadBookings, setFinalDownloadBookings] = useState([]);
  const { paymentId } = useParams<{ paymentId: string }>();
  const [totalAmountWithTaxAndFee, setTotalAmountWithTaxAndFee] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [showpay, setShowpay] = useState(false);
  const [selectedModal, setSelectedModal] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);

  const navigate = useNavigate();

  const location = useLocation();
  let allBookings = location.state?.bookingsWithTime || [];

  const nextLocation = localStorage.getItem("nextLocation");
  const sampleref = useRef(false);
  const isBlocked = useRef<any>(true);

  let blocker = useBlocker(({ currentLocation, nextLocation }: any) => {
    if (
      currentLocation.pathname !== nextLocation.pathname &&
      !sampleref.current
    ) {
      isBlocked.current = true;
      return true;
    }
    return false;
  });

  const handleClose = () => {
    setOpen(false);
    sampleref.current = true;

    navigate(routes.BOOKING_SERVICE);
    localStorage.removeItem("bookings");
    localStorage.removeItem("selectedService");
  };

  useEffect(() => {
    if (open) {
      const storedPaymentType = localStorage.getItem("paymentType");
      if (storedPaymentType) {
        setPaymentType(storedPaymentType); // Use this to set the state in the modal
      }
    }
  }, [open]);

  const handlegoBack = () => {
    sampleref.current = true;

    navigate(-1);
  };

  const habndlebacktoCheckOut = () => {
    setShowpay(false);
  };

  const cleanupLocalStorage = () => {
    localStorage.removeItem("bookings");
    localStorage.removeItem("selectedService");
  };

  const handlePhoneNumberChange = (e: any) => {
    const enteredPhoneNumber = e.target.value;
    setPhoneNumber(enteredPhoneNumber);

    // Clear the error when the user starts typing in the phone number field
    setPhoneNumberError("");

    // Phone number validation using a regex pattern (example: 10-digit US phone number)

    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(enteredPhoneNumber)) {
      setPhoneNumberError("Please enter a valid 10-digit phone number.");
    }
  };

  const handleEmailChange = (e: any) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
  };

  const handleConfirmation = () => {
    // Perform actions when the user confirms
    localStorage.removeItem("nextLocation");
    if (blocker.location) {
      localStorage.setItem("nextLocation", blocker.location.pathname);
    }
    isBlocked.current = false;
    sampleref.current = true;
    cleanupLocalStorage();
    setSelectedModal(false); // Close the modal
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  };

  const [timelostModal, setTimeLostModal] = useState(false);

  const handleTimerLost = () => {
    // Perform actions when the user confirms
    localStorage.removeItem("nextLocation");
    if (blocker.location) {
      localStorage.setItem("nextLocation", blocker.location.pathname);
    }
    isBlocked.current = false;
    sampleref.current = true;
    cleanupLocalStorage();
    setTimeLostModal(false); // Close the modal
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    navigate(routes.BOOKING_SERVICE);
  };

  const handleTimeLostModalClose = () => {
    // Handle user cancellation
    setTimeLostModal(false); // Close the modal
  };

  const handleModalCancel = () => {
    // Handle user cancellation
    setSelectedModal(false); // Close the modal
  };

  const onClear = () => {
    setLoadingClear(true); // Start loading

    setTimeout(() => {
      setApplyValue(0);
      setPayValue(0); // Reset pay value
      setApplyPromo("");

      setLoadingClear(false); // Stop loading
    }, 3000); // 3-second delay
  };

  const handleshowPaymentBox = () => {
    if (paymentType === "Advance Payment") {
      setPaymentMessage(
        "You are paying an advance payment of 30%, please do pay the remaining amount in the turf premises."
      );
    } else {
      setPaymentMessage("");
    }
    setLoading(true);
    setShowpay(false);
    setTimeout(() => {
      setLoading(false);
      setShowpay(true); // Show pay option after loading is done
    }, 1000); // Adjust the timeout as needed
  };
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const vertical = "top"; // or "top" based on your UI preference
  const horizontal = "right"; // or "left", "right" based on your UI preference

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseErrorSnackbar = () => {
    setSnackbarErrorOpen(false);
  };

  const onApply = () => {
    if (applyPromo !== "") {
      OffersApi.getOfferById(applyPromo)
        .then((data) => {
          if (data.active) {
            const discountAmount = totalAmountWithTaxAndFee * data.discount;

            // Calculate the discounted total amount
            setDiscountAmount(Math.round(discountAmount));

            const discountedTotalAmount =
              totalAmountWithTaxAndFee - discountAmount;

            // Set the calculated amounts
            setApplyValue(Math.round(discountedTotalAmount));
            setPayValue(Math.round(discountedTotalAmount * 0.3));

            // Show success toast message
            setSnackbarMessage("Coupon applied successfully!");
            setSnackbarOpen(true);
            // Reset form and close modal
            setApplyPromo("");
            setOpen1(false);
          } else {
            setOpen1(false);
            setSnackbarErrorOpen(true);
            setSnackbarMessage("Invalid Coupon");
          }
        })
        .catch((error: any) => {
          setOpen1(false);
          setSnackbarErrorOpen(true);
          setSnackbarMessage("Invalid Coupon");

          setApplyPromo("");
        });
    }
  };

  const [storedPayValue, setStoredPayValue] = useState<number | null>(null);

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isCheckedModalOpen, setIsCheckedModalOpen] = useState(false);

  const handlePayClick = async (finalTotalAmount: any) => {
    if (!isTermsChecked) {
      setIsCheckedModalOpen(true);
      return;
    }
    const bookId = await BookingApi.getBillingId("online");
    localStorage.setItem("finalTotalAmount", JSON.stringify(finalTotalAmount));

    setStoredPayValue(finalTotalAmount);

    setUniqueId(bookId);
    if (!phoneNumber) {
      setPhoneNumberError("Please enter your phone number.");

      window.scrollTo({ top: 0, behavior: "smooth" });

      return;
    }
    try {
      UserApi.updateById(userData.id, { phone: parseInt(phoneNumber), email })
        .then()
        .catch((error) => {
          alert(error);
        });
    } catch (error) {
      console.log("phone number is not valid");
    }

    if (userData) {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...userData, phone: phoneNumber, email })
      );
    }
    const flagType = paymentType === "Advance Payment" ? "advance" : "full";
    localStorage.setItem("paymentType", flagType);

    let finalBookings: any = [];
    allBookings.map(async (bookings: any) => {
      finalBookings.push({
        type: bookings.type,
        bookingtype: "online",
        startTime: parseInt(bookings.startTime),
        endTime: parseInt(bookings.endTime),
        user: userData.id,
        startDate: bookings.startDate,
        endDate: bookings.endDate,
        userBookingType: "online",
        court:
          BookingSubTypes[
            bookings.name as keyof typeof BookingSubTypes
          ].toString(),
        numberOfPerson:
          bookings.numberOfPersons > 0 ? bookings.numberOfPersons : 0,
        connectId: `${DateUtils.formatDate(new Date(), "DD/MM/YYYY")}-${
          bookings.type
        }-${userData.email}`,
        bookingAmount: {
          online: Math.round(finalTotalAmount / allBookings.length),
          total: Math.round(finalTotalAmount / allBookings.length),
          original: bookings.amount,
        },
        uniqueBillingId: bookId.toString(),
        PaymentFlagType: flagType,
        discountAmount: discountAmount ? discountAmount : 0,
      });
    });

    try {
      const response = await BookingApi.createBooking({
        bookings: finalBookings,
      });
      if (response) {
        let ids = response.map((data: { id: any }) => {
          return data.id;
        });
        const paymentResponse = await PaymentApi.createPayment({
          amount: finalTotalAmount,
          bookingId: ids,
        });
        if (paymentResponse) {
          console.log(flagType, "type1234");

          const res = await PaymentApi.createNewPayment({
            amount: finalTotalAmount,
            paymentId: paymentResponse.id,
          });
          if (res) {
            window.location.href = res.data.instrumentResponse.redirectInfo.url;
          }
        }
      } else {
        console.log("Booking Failed");
      }
    } catch (err: any) {
      alert(err.message);
    }
    sampleref.current = true;
    localStorage.removeItem("numberOfPersons");
  };

  const handleCloseCheckedModal = () => {
    setIsCheckedModalOpen(false);
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const triggerMail = async (paymentId: string) => {
      await PaymentApi.triggerSuccessEmail(paymentId);
    };
    console.log(finalDownloadBookings, "downloadBookings");

    if (paymentId && !emailRegex.test(paymentId)) {
      try {
        //trigger mail
        triggerMail(paymentId);

        PaymentApi.getPaymentById(paymentId)
          .then((bookings) => {
            if (bookings) {
              setFinalDownloadBookings(bookings);

              setOpen(true);
              console.log(finalDownloadBookings, "downloadBookings");
            }
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [paymentId]);

  useEffect(() => {
    let totalAmount = allBookings.reduce(
      (accumulator: number, booking: { amount: string }) =>
        accumulator + (parseFloat(booking.amount) || 0),
      0
    );
    // const taxAmount = totalAmount * 0.18;
    // const hasDiscountService = allBookings.some(
    //   (booking: any) => booking.name === "Turf 2.O & 3.O"
    // );

    // // Apply discount if applicable
    // if (hasDiscountService) {
    //   totalAmount -= 100;
    // }

    let cgst = totalAmount * 0.09;
    let sgst = totalAmount * 0.09;

    cgst = Math.round(cgst);
    sgst = Math.round(sgst);

    const convenienceFee = totalAmount * 0.03;

    const overAllTotal = totalAmount + cgst + sgst + convenienceFee;
    console.log(overAllTotal, "overAllTotaloverAllTotal");

    setTotalAmountWithTaxAndFee(Math.round(overAllTotal));

    const overAllAmount = totalAmount * (30 / 100);
    // let calculationAmountAdvance = overAllAmount + cgst + sgst + convenienceFee;
    let calculationAmountAdvance =
      overAllAmount + advancecgst + advancesgst + advanceconvenienceFee;

    console.log(overAllAmount, "overAllAmount123455");
    console.log(totalAmountWithTaxAndFee, "totalAmountWithTaxAndFee");
    console.log(calculationAmountAdvance, "calculationAmountAdvance");

    setFinalAmount(Math.round(calculationAmountAdvance));
  }, [allBookings, totalAmountWithTaxAndFee]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (nextLocation && !isBlocked.current) {
      localStorage.removeItem("nextLocation");
      navigate(nextLocation);
    }
  }, [nextLocation, navigate]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      // Instead of window.alert, set showModal to true
      setSelectedModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, blocker.state]);

  let totalBookingAmount = 0;

  // Iterate through all bookings
  allBookings.forEach((booking: { amount: string }) => {
    // Parse the amount string to a float and add it to the total
    totalBookingAmount += parseFloat(booking.amount);
  });
  // const hasDiscountService = allBookings.some(
  //   (booking: any) => booking.name === "Turf 2.O & 3.O"
  // );

  // // Apply discount if applicable
  // if (hasDiscountService) {
  //   totalBookingAmount -= 100;
  // }
  let cgst = totalBookingAmount * 0.09;
  let sgst = totalBookingAmount * 0.09;
  let AdvanceMakingPayment = totalBookingAmount * 0.3;

  let advancecgst = AdvanceMakingPayment * 0.09;
  let advancesgst = AdvanceMakingPayment * 0.09;
  let advanceconvenienceFee = AdvanceMakingPayment * 0.03;

  cgst = Math.round(cgst);
  sgst = Math.round(sgst);

  // advance

  console.log(AdvanceMakingPayment, "advance payment");
  advancecgst = Math.round(advancecgst);
  advancesgst = Math.round(advancesgst);
  advanceconvenienceFee = Math.round(advanceconvenienceFee);

  AdvanceMakingPayment = Math.round(AdvanceMakingPayment);

  let convenienceFee = totalBookingAmount * 0.03;

  let calculatedTotal = totalBookingAmount + cgst + sgst + convenienceFee;

  // advance
  let advancecalculatedTotal =
    AdvanceMakingPayment + advancecgst + advancesgst + advanceconvenienceFee;
  advancecalculatedTotal = Math.round(advancecalculatedTotal);
  console.log(advancecalculatedTotal, "advance payment");
  // advance

  calculatedTotal = Math.round(calculatedTotal);
  totalBookingAmount = Math.round(totalBookingAmount);

  console.log(advancecgst, "advancecgst");
  console.log(advancesgst, "advancesgst");
  console.log(advanceconvenienceFee, "advanceconvenienceFee");

  convenienceFee = Math.round(convenienceFee);
  const [paymentType, setPaymentType] = useState("Advance Payment");
  const [scrollToTop, setScrollToTop] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);

  const handleChange1 = (event: any) => {
    setPaymentType(event.target.value);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setApplyPromo("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    background: "#000333",
    p: "10px",
    borderRadius: "15px",
    maxWidth: "450px",
  };
  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setScrollToTop(false); // Reset the scrollToTop state after scrolling
    }
  }, [scrollToTop]);

  useEffect(() => {
    if (showpay) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [showpay]);

  const getDayName = (dateString: any) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };

  const [paymentMessage, setPaymentMessage] = useState("");

  const bannerImages = [bannerBG, bannerBG2, bannerBG3, bannerBG4];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % bannerImages.length
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [bannerImages.length]);

  const [loadingClear, setLoadingClear] = useState(false);

  console.log(storedPayValue, "storedPayValue when user click Pay");

  console.log("Slot Price", totalBookingAmount);

  const [timeLeft, setTimeLeft] = useState(180); // Initialize with total seconds (e.g., 5 minutes = 300 seconds + 4 seconds)

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear interval on component unmount
    } else {
      setTimeLostModal(true); // Open modal when timer runs out
    }
  }, [timeLeft]);

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      {showpay ? (
        <Box
          component={"image"}
          sx={{
            position: "relative",
            backgroundImage: `url(${backgroundimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            objectFit: "cover",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.92)",
            }}
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 1, // Ensure content appears above background
              color: "black", // Set text color for content
            }}
          >
            {showpay ? (
              <Box
                onClick={habndlebacktoCheckOut}
                padding={{ xs: "10px 0px", sm: "10px 0px", md: "10px 20px" }}
                gap={{ xs: "0px", sm: "0px", md: "7px" }}
                display={"flex"}
                alignItems={"center"}
              >
                <IconButton onClick={habndlebacktoCheckOut}>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat, sans-serif",
                      color: Colors.BUTTON,
                    }}
                  />
                </IconButton>
                <Typography
                  fontWeight={"600"}
                  fontSize={"18px"}
                  fontFamily={"'Montserrat', sans-serif"}
                  color={Colors.BUTTON}
                  onClick={habndlebacktoCheckOut}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Checkout Page
                </Typography>
              </Box>
            ) : (
              <Box
                padding={{ xs: " 20px 0px", md: "10px 20px" }}
                gap={"7px"}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  marginTop: { xs: "8rem", sm: "6rem", md: "8rem", lg: "1rem" },
                }}
              >
                <IconButton onClick={handlegoBack}>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat, sans-serif",
                      color: Colors.BUTTON,
                    }}
                  />
                </IconButton>
                <Typography
                  fontWeight={"600"}
                  fontSize={"18px"}
                  fontFamily={"'Montserrat', sans-serif"}
                  color={Colors.BUTTON}
                >
                  Booking Summary
                </Typography>
              </Box>
            )}
            <Box>
              {showpay ? (
                <Box
                  sx={{
                    opacity: "1",
                  }}
                  padding={{
                    xs: "0px 0px",
                    sm: "3px 35px",
                    md: "3px 35px",
                    lg: "0px 45px",
                  }}
                  width={"100%"}
                  maxWidth={{ xs: "90%", sm: "85", md: "295px", lg: "379px" }}
                  display={"flex"}
                  margin={"auto"}
                  flexDirection={"column"}
                >
                  <Box flexDirection={"column"} display={"flex"}>
                    <Typography
                      fontWeight={"600"}
                      fontFamily={"'Montserrat', sans-serif"}
                      color={Colors.BUTTON}
                      fontSize={"18px"}
                    >
                      Name:
                    </Typography>
                    <Typography
                      fontWeight={"600"}
                      color={"black"}
                      fontFamily={"'Montserrat', sans-serif"}
                      fontSize={"18px"}
                    >
                      {userData.name}
                    </Typography>
                  </Box>

                  <Typography
                    fontWeight={"600"}
                    fontFamily={"'Montserrat', sans-serif"}
                    color={Colors.BUTTON}
                    fontSize={"18px"}
                  >
                    Phone Number
                  </Typography>
                  <Box mt={"5px"}>
                    <TextField
                      sx={{
                        "& .MuiInputBase-input": {
                          fontFamily: "'Montserrat', sans-serif !important",
                          fontWeight: 600,
                          height: "15px",
                        },
                      }}
                      fullWidth
                      value={phoneNumber ?? ""}
                      onChange={handlePhoneNumberChange}
                      error={phoneNumberError !== ""}
                      helperText={phoneNumberError}
                      type="number"
                    />
                  </Box>
                  <Box mt={"5px"}>
                    <TextField
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "15px",
                          fontFamily: "'Montserrat', sans-serif !important",
                          fontWeight: 600,
                        },
                      }}
                      fullWidth
                      value={email ?? ""}
                      onChange={handleEmailChange}
                    />
                  </Box>
                  <Typography
                    pt={"30px"}
                    fontFamily={"'Montserrat', sans-serif"}
                    fontWeight={"600"}
                    color={Colors.BUTTON}
                    fontSize={"18px"}
                  >
                    Payment Methods
                  </Typography>
                  <Box
                    sx={{
                      opacity: "1",
                    }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <FormControl>
                      <RadioGroup
                        style={{ marginTop: "10px" }}
                        defaultValue="female"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={selectedPaymentMethod}
                        onChange={handlePaymentMethodChange}
                      >
                        <FormControlLabel
                          value="female"
                          control={<BpRadio />}
                          label=""
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      pt={"9px"}
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={"600"}
                      color={Colors.BLACK}
                      fontSize={"16px"}
                    >
                      Credit/Debit Cards
                    </Typography>
                  </Box>
                  <Box
                    // mt={"-10px"}
                    gap={"32px"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Typography
                      pl={"38px"}
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={"500"}
                      color={Colors.BLACK}
                      fontSize={"16px"}
                    >
                      Pay with your Credit / Debit Card
                    </Typography>
                    {/* <img src={cardicons} alt="" width={"80px"} /> */}
                  </Box>
                  <Box
                    margin={"15px 0px "}
                    width={"100%"}
                    // maxWidth={"340px"}
                    height={"35px"}
                    bgcolor={"#eae8e8"}
                  >
                    <Stack
                      sx={{
                        opacity: "1",
                      }}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      padding={"5px 25px"}
                    >
                      <Typography
                        fontFamily={"'Montserrat', sans-serif"}
                        fontWeight={"500"}
                        color={Colors.BLACK}
                        fontSize={"16px"}
                      >
                        Card Number
                      </Typography>
                      <img src={crediticon} alt="" width={"25px"} />
                    </Stack>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    margin={"15px 0px "}
                    gap={"12px"}
                    justifyContent={"space-between"}
                  >
                    <Box
                      width={"100%"}
                      maxWidth={"230px"}
                      height={"35px"}
                      bgcolor={"#eae8e8"}
                    >
                      <Stack
                        sx={{
                          opacity: "1",
                        }}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        padding={"5px 25px"}
                      >
                        <Typography
                          fontFamily={"'Montserrat', sans-serif"}
                          fontWeight={"500"}
                          color={Colors.BLACK}
                          fontSize={"16px"}
                        >
                          MM / YY
                        </Typography>
                        <TodayIcon
                          style={{
                            fontSize: "20px",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box
                      width={"100%"}
                      maxWidth={"100px"}
                      height={"35px"}
                      bgcolor={"#eae8e8"}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        padding={"5px 10px"}
                      >
                        <Typography
                          fontFamily={"'Montserrat', sans-serif"}
                          fontWeight={"500"}
                          color={Colors.BLACK}
                          fontSize={"16px"}
                        >
                          CVV
                        </Typography>
                        <LockIcon
                          style={{
                            fontSize: "20px",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        />
                      </Stack>
                    </Box>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <FormControl>
                      <RadioGroup
                        style={{ marginTop: "10px" }}
                        defaultValue="other"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={selectedPaymentMethod}
                        onChange={handlePaymentMethodChange}
                      >
                        <FormControlLabel
                          value="other"
                          control={<BpRadio />}
                          label=""
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      pt={"9px"}
                      fontFamily={"'Montserrat', sans-serif"}
                      color={"black"}
                      fontSize={"16px"}
                      fontWeight={"bold"}
                    >
                      Direct Bank Transfer
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily={"'Montserrat', sans-serif"}
                    pl={{ xs: "42px", sm: "42px", md: "42px", lg: "38px" }}
                    color={"black"}
                    fontSize={{
                      xs: "12px",
                      sm: "12px",
                      md: "12px",
                      lg: "16px",
                    }}
                    fontWeight={"500"}
                  >
                    Make payment directly through bank account.
                  </Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <FormControl>
                      <RadioGroup
                        style={{ marginTop: "10px" }}
                        defaultValue="male"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={selectedPaymentMethod}
                        onChange={handlePaymentMethodChange}
                      >
                        <FormControlLabel
                          value="male"
                          control={<BpRadio />}
                          label=""
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      pt={"9px"}
                      fontFamily={"'Montserrat', sans-serif"}
                      color={"black"}
                      fontSize={"16px"}
                      fontWeight={"bold"}
                    >
                      Other Payment Methods
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily={"'Montserrat', sans-serif"}
                    pl={{ xs: "42px", sm: "42px", md: "42px", lg: "38px" }}
                    color={"black"}
                    fontSize={{
                      xs: "12px",
                      sm: "12px",
                      md: "12px",
                      lg: "16px",
                    }}
                    fontWeight={"500"}
                  >
                    Make payment through Gpay, Paytm etc
                  </Typography>
                  {paymentMessage && (
                    <Box
                      width={{ xs: "94%", sm: "94%", md: "100%" }}
                      // maxWidth={{ xs: "275px", md: "320px" }}
                      border={"2px dashed #15B5FC"}
                      mt={"20px"}
                      padding={{ xs: "10px", md: "25px" }}
                    >
                      <Typography
                        color={Colors.BUTTON_COLOR}
                        fontFamily={"'Montserrat', sans-serif"}
                        fontSize={{ xs: "15px", md: "16px" }}
                        fontWeight={600}
                      >
                        {paymentMessage}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Checkbox
                      checked={isTermsChecked}
                      onChange={(e) => setIsTermsChecked(e.target.checked)}
                    />{" "}
                    <Typography
                      fontSize={{
                        xs: "12px",
                        sm: "12px",
                        md: "12px",
                        lg: "16px",
                      }}
                      fontFamily={"'Montserrat', sans-serif"}
                    >
                      I agree to the Terms and Conditions
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      opacity: "1",
                    }}
                    mt={"20px"}
                    mb={{ xs: "30px", sm: "30px", md: "0" }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"15px"}
                  >
                    <Button
                      onClick={() =>
                        handlePayClick(
                          paymentType === "Advance Payment"
                            ? applyValue
                              ? payValue
                              : finalAmount // Check for applyValue only once
                            : applyValue || calculatedTotal
                        )
                      }
                      sx={{
                        width: "100%",
                        maxWidth: "160px",
                        height: "40px",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        fontFamily: "'Montserrat', sans-serif",
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        color: Colors.WHITE,
                        ":hover": {
                          background: Colors.WHITE,
                          color: Colors.BUTTON_COLOR,
                          border: "1px solid #15B5FC",
                        },
                      }}
                      variant="contained"
                    >
                      Pay{" "}
                      {
                        paymentType === "Advance Payment"
                          ? applyValue
                            ? payValue
                            : finalAmount // Check for applyValue only once
                          : applyValue || calculatedTotal // If applyValue exists, use it, otherwise fallback to calculatedTotal
                      }
                      {""} .Rs
                    </Button>
                    <div
                      style={{
                        display: open ? "none" : "block",
                        textAlign: "center",
                        fontFamily: "Arial, sans-serif",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <AccessTimeIcon
                          style={{
                            fontSize: "28px",
                            color: Colors.BUTTON_COLOR,
                          }}
                        />
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            margin: 0,
                          }}
                        >
                          {formatTime(timeLeft)} minutes
                        </p>
                      </div>
                    </div>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>

            {showpay ? (
              ""
            ) : (
              <>
                <Box
                  margin={"auto"}
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"25px"}
                  mt={3}
                >
                  <Button
                    sx={{
                      border: "1px solid #21CBF3",
                      backgroundColor: "white", // Initial background color
                      textTransform: "none",
                      fontFamily: "Montserrat, sans-serif",
                      width: "fit-content",
                      color: "black", // Change text color to contrast with white background
                      fontWeight: 700,
                      position: "relative", // Required for positioning pseudo-element
                      overflow: "hidden", // Ensure the pseudo-element doesn't overflow
                      alignItems: "center",
                      gap: "10px",
                      transition:
                        "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background color and icon movement
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        borderRadius: "inherit", // Match the button's border-radius
                        border: "2px solid transparent", // Transparent border to show gradient border
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border color
                        zIndex: -1, // Ensure the border is behind the button
                        transition: "background 0.3s ease", // Smooth transition for gradient border
                      },
                      "&:hover": {
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient background on hover
                        color: "white", // Change text color to white on hover
                        "&::before": {
                          background:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border on hover
                        },
                        "& .MuiSvgIcon-root": {
                          transform: "translateX(40px)", // Move icon to the right on hover
                        },
                      },
                    }}
                    onClick={handleshowPaymentBox}
                  >
                    Process to Payment
                    <ArrowForwardIcon
                      sx={{
                        transition: "transform 0.3s ease", // Smooth transition for the icon
                      }}
                    />
                  </Button>

                  <Button
                    sx={{
                      width: "fit-content",
                      height: "40px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      background: Colors.BUTTON_COLOR,
                      color: Colors.WHITE,
                      border: "1px solid #15B5FC",
                      fontFamily: "'Montserrat', sans-serif",
                      ":hover": {
                        background: Colors.WHITE,
                        color: Colors.BUTTON_COLOR,
                        border: "1px solid #15B5FC",
                      },
                    }}
                    variant="contained"
                    onClick={handlegoBack}
                  >
                    Back
                  </Button>
                </Box>

                <Box padding={"20px 20px"} maxWidth={550} margin={"auto"}>
                  <Box
                    padding={{ xs: "10px", md: "25px" }}
                    border={`2px  dashed  ${Colors.BUTTON_COLOR}`}
                  >
                    <Typography
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={700}
                      textAlign={"start"}
                      color={Colors.BUTTON_COLOR}
                    >
                      Important Note:-
                    </Typography>
                    <Typography
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={700}
                      textAlign={"center"}
                      color={Colors.BUTTON_COLOR}
                    >
                      You are now going to pay 30% of the total in advance, and
                      the remaining fee has to be paid in Playzo Front Office by
                      showing the booking receipt generated post the payment.
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            <Stack
              sx={{
                opacity: "1",
              }}
              margin={{ xs: "0px", sm: "0px", md: "0px", lg: "50px 190px" }}
              alignItems={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "normal",
              }}
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
              }}
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              }}
            ></Stack>
          </Box>
        </Box>
      ) : (
        <Box
          component={"image"}
          sx={{
            position: "relative",
            backgroundImage: `url(${backgroundimage})`,
            backgroundSize: "cover", // Fit the background image to cover the entire container
            // backgroundPosition: "bottom",
            backgroundRepeat: "repeat",
            objectFit: "cover", // Set object-fit to cover
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.92)",
            }}
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 1, // Ensure content appears above background
              color: "black", // Set text color for content
            }}
          >
            <Stack width={"100%"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                paddingTop={{ xs: "20px", md: "" }}
                sx={{
                  position: { xs: "relative", md: "absolute" },
                  translate: { xs: "0px", md: "0px 0px", lg: "0px 354px" },
                }}
              >
                <IconButton onClick={handlegoBack}>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat, sans-serif",
                      color: Colors.BUTTON,
                    }}
                  />
                </IconButton>
                <Typography
                  fontWeight={"600"}
                  fontSize={"18px"}
                  fontFamily={"'Montserrat', sans-serif"}
                  color={Colors.BUTTON}
                  onClick={handlegoBack}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Booking Page
                </Typography>
              </Box>
              <Box display={{ xs: "flex", md: "block" }} alignItems={"center"}>
                <Box
                  sx={{
                    position: "relative",
                    width: { xs: "auto", md: "100%" },
                    height: { xs: "auto", md: "auto", lg: 350 },
                    overflow: "hidden",
                  }}
                >
                  <BannerImage
                    src={bannerImages[currentImageIndex]}
                    alt="Banner"
                    style={{
                      objectFit: "cover",
                      transition: "opacity 1s ease-in-out", // Smooth transition between images
                      opacity: 1,
                    }}
                  />
                </Box>

                <Box
                  mt={{ xs: "0px", md: "20px" }}
                  display={{ xs: "flex", md: "flex" }}
                  justifyContent={"center"}
                  mb={{ xs: "0px", md: "20px" }}
                  margin={{ xs: "auto", md: "" }}
                  pt={{ xs: "15px", md: "0px" }}
                  pb={{ xs: "15px", md: "0px" }}
                >
                  <LogoImage
                    src={
                      "https://playzo33-assets.s3.ap-south-1.amazonaws.com/assets/playzo33-logo.png"
                    }
                    alt="logo"
                  />
                </Box>
              </Box>
              <Box display={{ xs: "none", md: "block" }}>
                <Divider
                  orientation="horizontal"
                  sx={{
                    marginTop: "20px",
                  }}
                />
              </Box>
              <Typography
                pl={{ xs: "20px", md: "0px" }}
                mt={{ xs: "0px", md: "25px" }}
                textAlign={{ xs: "start", md: "center" }}
                fontFamily={"'Montserrat', sans-serif"}
                sx={{
                  fontSize: { xs: "20px", md: "28px" },
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
                display={{ xs: "block", md: "block" }}
                pb={{ xs: "0px", md: "10px" }}
              >
                Your Bookings
              </Typography>
              <Box
                pl={{ xs: "24px", md: "0px" }}
                display={"flex"}
                justifyContent={{ xs: "flex-start", md: "center" }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    width: { xs: "24%", md: "15%" },
                    backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                    height: "2px", // Adjust the height to make it more visible if needed
                  }}
                />
              </Box>
              <Box
                width={{ xs: "100%", md: "100%" }}
                maxWidth={{ xs: "700px", sm: "768px", md: "700px" }}
                border={{ xs: "none", md: "1px solid lightgrey" }}
                display={{ xs: "block", md: "none" }}
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent={"center"}
                gap={"50px"}
                alignItems={"center"}
                margin={{ xs: "0px", md: "0 auto" }}
                mt={{ xs: "0px", md: "10px" }}
                sx={{
                  background: { xs: "none", md: "white" },
                  borderRadius: "15px",
                  "&::-webkit-scrollbar-thumb": {
                    background: "#15B5FC", // Scrollbar thumb color
                    borderRadius: "10px", // Scrollbar thumb border radius
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#0E8ACC", // Thumb color on hover
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f0f0f0", // Scrollbar track color
                  },
                }}
                overflow={"auto"}
                height={"100%"}
                maxHeight={{ xs: "126px", md: "142px" }}
                marginLeft={{ xs: "-5px", md: "0px" }}
              >
                {allBookings.map(
                  (
                    booking: {
                      startTime: string | number | Date;
                      endTime: string | number | Date;
                      type:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined;
                      name:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined;
                      duration: string | number | boolean;
                      amount: string;

                      numberOfPersons: string;
                    },
                    index: number
                  ) => {
                    const startDateTime = new Date(booking.startTime);
                    const endDateTime = new Date(booking.endTime);

                    const startHours = startDateTime.getHours();
                    const endHours = endDateTime.getHours();

                    const formattedStartDate = startDateTime.toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );

                    // Format start and end times without minutes
                    const formattedStartTime = `${startHours % 12 || 12}:00`;
                    const formattedEndTime = `${endHours % 12 || 12}:00 ${
                      endHours < 12 ? "AM" : "PM"
                    }`;

                    const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

                    return (
                      <Box
                        key={index} // Add a key for each item in the list
                        // borderBottom={"1px solid lightgrey"}
                        display={"flex"}
                        flexDirection={{ xs: "column", md: "row" }}
                        justifyContent={"center"}
                        alignItems={{ xs: "start", md: "center" }}
                        mt={{ xs: "16px", md: "10px" }}
                        pl={{ xs: "20px", md: "0px" }}
                        sx={{
                          background: { xs: "none", md: "white" },
                        }}
                        gap={{ xs: "0px", md: "20px" }}
                        pb={{ xs: "18px", md: "10px" }}
                        borderBottom={{
                          xs: "1px solid black",
                          md: "1px solid lightgray",
                        }}
                      >
                        <Box
                          display={{ xs: "flex", md: "flex" }}
                          gap={{ xs: "5px", md: "10px" }}
                        >
                          <Typography
                            fontFamily={"'Montserrat', sans-serif"}
                            fontSize={{ xs: "17px", md: "18px" }}
                            fontWeight={600}
                          >
                            {index + 1}.
                          </Typography>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"10px"}
                          >
                            {booking.type === BookingType.Turf ? (
                              <SportsCricketIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "25px" },
                                }}
                              />
                            ) : booking.type === BookingType.Playstaion ? (
                              <SportsEsportsIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "25px" },
                                }}
                              />
                            ) : booking.type === BookingType.BoardGame ? (
                              <BoardImage src={boardImage} alt="Board Game" />
                            ) : booking.type === BookingType.Badminton ? (
                              <SportsTennisIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "25px" },
                                }}
                              />
                            ) : booking.type === BookingType.CricketNet ? (
                              <CricketNetImage
                                src={turfImage}
                                alt="Cricket Net"
                              />
                            ) : booking.type === BookingType.BowlingMachine ? (
                              <BowlingMachineImage
                                src={BowlingMachine}
                                alt="Bowling Machine"
                              />
                            ) : (
                              "other"
                            )}
                          </Box>
                          <Box display={"flex"} gap={"10px"}>
                            <Typography
                              fontFamily={"'Montserrat', sans-serif"}
                              textTransform={"capitalize"}
                              fontSize={{ xs: "17px", md: "18px" }}
                              fontWeight={600}
                            >
                              {booking.type === ("turf" as BookingType)
                                ? "Turf / Football"
                                : booking.type}{" "}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"10px"}
                        >
                          <CalendarTodayIcon style={{ fontSize: "15px" }} />
                          <Typography
                            fontFamily={"'Montserrat', sans-serif"}
                            fontWeight={600}
                            textTransform={"capitalize"}
                            fontSize={{ xs: "17px", md: "18px" }}
                          >
                            {new Date(formattedStartDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "short", // 'Sep'
                                day: "numeric", // '10'
                              }
                            )}{" "}
                            ({getDayName(formattedStartDate)})
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"10px"}
                        >
                          <AccessTimeIcon style={{ fontSize: "16px" }} />
                          <Typography
                            fontFamily={"'Montserrat', sans-serif"}
                            textTransform={"capitalize"}
                            fontSize={{ xs: "17px", md: "18px" }}
                            fontWeight={600}
                          >
                            {formattedTimeRange}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "800px",
                  margin: "0 auto",
                  mt: { xs: "22px", md: "25px" },
                  borderRadius: "15px",
                  maxHeight: { xs: "126px", md: "178px" },
                  height: "100%",
                  overflow: "auto",
                  display: { xs: "none", md: "block" },
                  marginBottom: "15px",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#15B5FC",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f0f0f0",
                  },
                  boxShadow:
                    "-2px -2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="booking table">
                  <TableHead>
                    <TableRow></TableRow>
                  </TableHead>
                  <TableBody>
                    {allBookings.map(
                      (
                        booking: {
                          startTime: string | number | Date;
                          endTime: string | number | Date;
                          type:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                          name:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                          duration: string | number | boolean;
                          amount: string;
                          numberOfPersons: string;
                        },
                        index: number
                      ) => {
                        const startDateTime = new Date(booking.startTime);
                        const endDateTime = new Date(booking.endTime);

                        const startHours = startDateTime.getHours();
                        const endHours = endDateTime.getHours();

                        const formattedStartDate =
                          startDateTime.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          });

                        // Format start and end times without minutes
                        const formattedStartTime = `${
                          startHours % 12 || 12
                        }:00`;
                        const formattedEndTime = `${endHours % 12 || 12}:00 ${
                          endHours < 12 ? "AM" : "PM"
                        }`;

                        const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{
                                fontWeight: "600",
                                fontSize: "17px",
                                fontFamily: "Montserrat, sans-serif",
                              }}
                            >
                              {index + 1}.
                            </TableCell>
                            <TableCell
                              sx={{
                                display: { xs: "block", md: "flex" },
                                gap: "10px",
                              }}
                              component="th"
                              scope="row"
                            >
                              {booking.type === BookingType.Turf ? (
                                <SportsCricketIcon
                                  sx={{
                                    fontSize: { xs: "20px", md: "25px" },
                                  }}
                                />
                              ) : booking.type === BookingType.Playstaion ? (
                                <SportsEsportsIcon
                                  sx={{
                                    fontSize: { xs: "20px", md: "25px" },
                                  }}
                                />
                              ) : booking.type === BookingType.BoardGame ? (
                                <BoardImage src={boardImage} alt="Board Game" />
                              ) : booking.type === BookingType.Badminton ? (
                                <SportsTennisIcon
                                  sx={{
                                    fontSize: { xs: "20px", md: "25px" },
                                  }}
                                />
                              ) : booking.type === BookingType.CricketNet ? (
                                <CricketNetImage
                                  src={turfImage}
                                  alt="Cricket Net"
                                />
                              ) : booking.type ===
                                BookingType.BowlingMachine ? (
                                <BowlingMachineImage
                                  src={BowlingMachine}
                                  alt="Bowling Machine"
                                />
                              ) : (
                                "other"
                              )}
                              <Typography
                                fontFamily={"'Montserrat', sans-serif"}
                                textTransform={"capitalize"}
                                fontSize={{ xs: "17px", md: "18px" }}
                                fontWeight={600}
                              >
                                {booking.type === ("turf" as BookingType)
                                  ? "Turf / Football"
                                  : booking.type}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="10px"
                              >
                                <CalendarTodayIcon
                                  style={{ fontSize: "15px" }}
                                />
                                <Typography
                                  fontFamily={"'Montserrat', sans-serif"}
                                  fontWeight={600}
                                  textTransform={"capitalize"}
                                  whiteSpace={"nowrap"}
                                  fontSize={{ xs: "17px", md: "18px" }}
                                  fontStyle={"italic"}
                                >
                                  {new Date(
                                    formattedStartDate
                                  ).toLocaleDateString("en-US", {
                                    month: "short", // 'Sep'
                                    day: "numeric", // '10'
                                  })}{" "}
                                  ({getDayName(formattedStartDate)})
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="10px"
                              >
                                <AccessTimeIcon style={{ fontSize: "16px" }} />
                                <Typography
                                  whiteSpace={"nowrap"}
                                  fontFamily={"'Montserrat', sans-serif"}
                                  textTransform={"capitalize"}
                                  fontSize={{ xs: "17px", md: "18px" }}
                                  fontWeight={600}
                                >
                                  {formattedTimeRange}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display={{ xs: "none", md: "flex" }}
                justifyContent={"center"}
                pb={{ xs: "0px", md: "10px" }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                    marginTop: "20px",
                    background: { xs: "#7b7676", md: "lightgrey" },
                  }}
                />
              </Box>

              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                mt={{ xs: "0px", md: "10px" }}
                alignItems={{ xs: "baseline", md: "center" }}
                boxShadow={{ xs: "2px 5px 10px -2px lightgrey", md: "none" }}
              >
                <FormControl
                  sx={{
                    paddingLeft: { xs: "27px", md: "0px" },
                    paddingTop: { xs: "10px", md: "0px" },
                    paddingBottom: { xs: "10px", md: "0px" },
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={paymentType}
                    onChange={handleChange1}
                  >
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontSize: { xs: "16px", md: "20px" },
                          fontFamily: "Montserrat, sans-serif",
                          textTransform: "capitalize",
                          fontWeight: 600,
                        },
                        ".MuiRadio-root.Mui-checked": {
                          color: Colors.BUTTON_COLOR,
                        },
                      }}
                      value="Advance Payment"
                      control={<Radio />}
                      label="Advance Payment"
                    />
                    <Typography
                      mt={"-8px"}
                      ml={"34px"}
                      fontSize={"14px"}
                      color={Colors.LIGHT_GRAY}
                      fontFamily={"Montserrat, sans-serif"}
                    >
                      (30% of Total price)
                    </Typography>
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontSize: { xs: "16px", md: "20px" },
                          fontFamily: "Montserrat, sans-serif",
                          textTransform: "capitalize",
                          fontWeight: 600,
                        },
                      }}
                      value="Full Payment"
                      control={<Radio />}
                      label="Full Payment"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                display={{ xs: "none", md: "flex" }}
                justifyContent={"center"}
                pb={{ xs: "0px", md: "35px" }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                    marginTop: "20px",
                    background: { xs: "#7b7676", md: "lightgrey" },
                  }}
                />
              </Box>

              <Box
                display={"flex"}
                width={{ xs: "auto", md: "100%" }}
                maxWidth={{ xs: "none", md: "300px" }}
                padding={{ xs: "0px 20px", md: "0 20px" }}
                flexDirection={"column"}
                gap={"5px"}
                margin={{ xs: "0px", md: "0 auto" }}
                border={{ xs: "none", md: "1px solid lightgrey" }}
                boxShadow={{ xs: "2px 5px 10px -2px lightgrey", md: "none" }}
                sx={{
                  background: { xs: "none", md: "white" },
                  borderRadius: { xs: "5px", md: "15px" },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"0px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={{ xs: "20px", md: "0px" }}
                >
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={{ xs: "20px", md: "0px" }}
                    width={"100%"}
                  >
                    <p
                      style={{
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        margin: 0,
                        fontSize: "16px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Slot Price:
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {" "}
                      <b style={{ fontWeight: "500", color: "black" }}>
                        {totalBookingAmount}.Rs
                      </b>
                    </p>{" "}
                  </Box>
                  {paymentType === "Advance Payment" ? (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      gap={"10px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={{ xs: "-10px", md: "-10px" }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: 600,
                          margin: 0,
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        ( You're paying 30% in advance )
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        {" "}
                        <b style={{ fontWeight: "500", color: "black" }}>
                          {AdvanceMakingPayment}.Rs
                        </b>
                      </p>{" "}
                    </Box>
                  ) : (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      gap={"10px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={{ xs: "-10px", md: "-10px" }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: 600,
                          margin: 0,
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        ( You're paying full amount )
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        {" "}
                        <b style={{ fontWeight: "500", color: "black" }}>
                          {totalBookingAmount}.Rs
                        </b>
                      </p>{" "}
                    </Box>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"baseline"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                >
                  {" "}
                  <p
                    style={{
                      color: "black",
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontWeight: 600,
                      fontSize: "16px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    Online Convenience <br /> Fee :
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    <b style={{ fontWeight: "500", color: "black" }}>
                      {paymentType === "Advance Payment"
                        ? advanceconvenienceFee
                        : convenienceFee}{" "}
                      {/* {convenienceFee} */}
                      .Rs
                    </b>
                  </p>{" "}
                </Box>

                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {" "}
                  <p
                    style={{
                      color: "black",
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontWeight: 600,
                      fontSize: "16px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    CGST (9%):
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    <b style={{ fontWeight: "500", color: "black" }}>
                      {paymentType === "Advance Payment" ? advancecgst : cgst}{" "}
                      .Rs
                    </b>
                  </p>
                </Box>

                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <p
                    style={{
                      color: "black",
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontWeight: 600,
                      fontSize: "16px",

                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    SGST (9%):
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    <b style={{ fontWeight: "500", color: "black" }}>
                      {paymentType === "Advance Payment" ? advancesgst : sgst}{" "}
                      .Rs
                    </b>
                  </p>
                </Box>

                {applyValue !== 0 && (
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <p
                      style={{
                        color: "black",
                        whiteSpace: "nowrap",
                        margin: 0,
                        fontWeight: 600,
                        fontSize: "16px",

                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Discount Amount:
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      <b style={{ fontWeight: "500", color: "black" }}>
                        {discountAmount} .Rs
                      </b>
                    </p>
                  </Box>
                )}
                <Divider
                  sx={{
                    // maxWidth: 200,
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                />
                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <p
                    style={{
                      color: "black",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                      margin: 0,
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    {paymentType === "Advance Payment"
                      ? "Advance Payment:"
                      : "Total Amount:"}
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    <b>
                      {
                        paymentType === "Advance Payment"
                          ? applyValue // Check if coupon is applied for Advance Payment
                            ? payValue // Use applied value if coupon is applied
                            : finalAmount // Otherwise, use final amount
                          : applyValue // Check if coupon is applied for normal payment
                          ? applyValue // Use applied value if coupon is applied
                          : calculatedTotal // Otherwise, use calculated total
                      }
                      .Rs
                    </b>{" "}
                  </p>
                </Box>
              </Box>

              <Box
                width={"100%"}
                display={{ xs: "flex", md: "flex" }}
                justifyContent={{ xs: "center", md: "center" }}
                margin={{ xs: "0px", md: "auto" }}
              >
                {applyValue ? (
                  <Button
                    sx={{
                      width: "100%",
                      maxWidth: { xs: "190px", md: "200px" },
                      height: { xs: "40px", md: "40px" },
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      background: {
                        xs: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        md: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      },
                      marginTop: { xs: "27px", md: "20px" },
                      color: { xs: Colors.WHITE, md: Colors.WHITE },
                      fontSize: "18px",
                      boxShadow: "none",
                      ":hover": {
                        background: Colors.WHITE,
                        color: Colors.BUTTON_COLOR,
                        border: { xs: "none", md: "1px solid #15B5FC" },
                      },
                    }}
                    variant="contained"
                    onClick={onClear}
                    disabled={loadingClear} // Disable button when loading
                  >
                    {loadingClear ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "white",
                        }}
                      /> // Show loader
                    ) : (
                      "Clear"
                    )}
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "100%",
                      maxWidth: { xs: "190px", md: "200px" },
                      height: { xs: "40px", md: "40px" },
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      background: {
                        xs: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        md: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      },
                      marginTop: { xs: "27px", md: "20px" },

                      color: { xs: Colors.WHITE, md: Colors.WHITE },
                      fontSize: "18px",
                      boxShadow: "none",
                      ":hover": {
                        background: Colors.WHITE,
                        color: Colors.BUTTON_COLOR,
                        border: { xs: "none", md: "1px solid #15B5FC" },
                      },
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    Apply Coupon
                  </Button>
                )}
              </Box>
              <Box
                maxWidth={500}
                margin={{ xs: "auto", md: "auto" }}
                display={{ xs: "flex" }}
                justifyContent={{ xs: "start", md: "center" }}
                gap={"25px"}
                mt={{ xs: "30px", md: "20px" }}
                paddingBottom={2}
                alignItems={"center"}
              >
                <Button
                  sx={{
                    border: "1px solid #21CBF3",
                    backgroundColor: loading ? "#2196F3" : "white",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                    width: "100%",
                    maxWidth: { xs: "238px", md: "238px" },
                    color: "black",
                    fontWeight: 700,
                    position: "relative",
                    overflow: "hidden",
                    alignItems: "center",
                    gap: "10px",
                    transition:
                      "background-color 0.3s ease, transform 0.3s ease",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      borderRadius: "inherit",
                      border: "2px solid transparent",
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      zIndex: -1,
                      transition: "background 0.3s ease",
                    },
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      color: "white",
                      "&::before": {
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      },
                      "& .MuiSvgIcon-root": {
                        transform: "translateX(40px)",
                      },
                    },
                  }}
                  onClick={handleshowPaymentBox}
                  disabled={loading} // Disable button while loading
                  startIcon={
                    loading ? (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    ) : null
                  }
                >
                  {loading ? "" : "Proceed to Payment"}
                  {!loading && (
                    <ArrowForwardIcon
                      sx={{
                        transition: "transform 0.3s ease",
                      }}
                    />
                  )}
                </Button>
                <div
                  style={{
                    display: open ? "none" : "block",
                    textAlign: "center",
                    fontFamily: "Arial, sans-serif",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <AccessTimeIcon
                      style={{ fontSize: "28px", color: Colors.BUTTON_COLOR }}
                    />
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      {formatTime(timeLeft)} minutes
                    </p>
                  </div>
                </div>
              </Box>

              {paymentType === "Advance Payment" && (
                <Box padding={"20px 20px"} maxWidth={550} margin={"auto"}>
                  <Box
                    padding={"25px"}
                    border={`2px dashed ${Colors.BUTTON_COLOR}`}
                    mt={{ xs: "0px", md: "20px" }}
                    mb={{ xs: "0px", md: "15px" }}
                  >
                    <Typography
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={700}
                      textAlign={"start"}
                      color={Colors.BACK_BLACK}
                    >
                      ⚠️ Important Note:-
                    </Typography>
                    <Typography
                      fontFamily={"'Montserrat', sans-serif"}
                      fontWeight={700}
                      textAlign={"center"}
                      color={Colors.BUTTON_COLOR}
                      pt={"10px"}
                    >
                      Pay 30% now. Settle the remaining fee at Playzo Front
                      Office with your booking receipt.
                    </Typography>
                  </Box>
                </Box>
              )}
              <Modal
                open={open1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box width={{ xs: "80%", sm: "85%", md: "100%" }} sx={style}>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    mt={2}
                  >
                    <Typography
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                      ml={"10px"}
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"white"}
                    >
                      Coupon Available
                    </Typography>
                    <IconButton onClick={handleClose1}>
                      <CloseIcon style={{ fontSize: "20px", color: "white" }} />
                    </IconButton>
                  </Box>
                  <Box padding={"10px"}>
                    <Typography
                      fontWeight={600}
                      color={"white"}
                      fontSize={"15px"}
                      fontFamily={"'Montserrat', sans-serif"}
                    >
                      Promo code
                    </Typography>
                    <CustomTextField
                      sx={{
                        width: "100%",
                        height: "30px",
                        ".MuiOutlinedInput-root": {
                          width: "100%",
                          height: "30px",
                          border: "1px solid white",
                          color: "white",
                        },
                      }}
                      value={applyPromo}
                      onChange={setApplyPromo}
                      id="outlined-password-input"
                    />

                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                      spacing={{ xs: 1, sm: 2 }}
                      mt={3}
                      mb={2}
                    >
                      <Button
                        sx={{
                          border: "1px solid #15B5FC",
                          width: "100%",
                          maxWidth: "60px",
                          height: "30px",
                          marginTop: "16px",
                          textTransform: "capitalize",
                          background:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                          color: Colors.WHITE,
                          fontFamily: "Montserrat, sans-serif",
                          ":hover": {
                            background: Colors.WHITE,
                            color: Colors.BUTTON_COLOR,
                            border: "1px solid #15B5FC",
                          },
                        }}
                        variant="outlined"
                        onClick={onApply}
                      >
                        Apply
                      </Button>
                      <Button
                        sx={{
                          border: "1px solid #15B5FC",
                          width: "100%",
                          maxWidth: "60px",
                          height: "30px",
                          marginTop: "16px",
                          textTransform: "capitalize",
                          background:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                          color: Colors.WHITE,
                          fontFamily: "'Montserrat', sans-serif",
                          ":hover": {
                            background: Colors.WHITE,
                            color: Colors.BUTTON_COLOR,
                            border: "1px solid #15B5FC",
                          },
                        }}
                        variant="outlined"
                        onClick={onClear}
                      >
                        Clear
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </Box>
        </Box>
      )}

      <ModalComponent
        open={open}
        handleClose={handleClose}
        pdf={true}
        data={finalDownloadBookings}
        // data={allBookings}
        text="Thank you, Your booking is confirmed"
        headingText="Booking Confirmation"
        // paymentType={paymentFlagType}
        paymentText={
          "You have paid 30% of the total in advance, and the remaining fee has to be paid in Playzo Front Office by showing the booking receipt generated post the payment."
        }
        applyValue={applyValue}
        payValue={payValue}
        finalAmount={finalAmount}
        discountAmount={discountAmount}
        StoredAmount={storedPayValue}
      />

      <ModalComponent
        open={isCheckedModalOpen}
        handleClose={handleCloseCheckedModal}
        text={
          "Please agree to the Terms and Conditions before proceeding with payment."
        }
        headingText={"Terms and Conditions Agreement"}
      />

      <BookingParantmodal
        open={selectedModal}
        handleClose={handleModalCancel}
        text={
          "Are you sure you want to leave? Your selected service will be lost."
        }
        handleConfirmReset={handleConfirmation}
      />

      <BookingParantmodal
        open={timelostModal}
        handleClose={handleTimeLostModalClose}
        text={
          "Your time has expired. Please restart the booking process to continue."
        }
        handleConfirmReset={handleTimerLost}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarErrorOpen}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
