/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Slider,
  Stack,
  Typography,
  keyframes,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BookingApi from "../../api/BookingApi";
import { BookingSubTypes } from "../../BookingService/BookingSubTypes";
import { BookingType } from "../../CommonFiles/BookingType";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CasinoIcon from "@mui/icons-material/Casino";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../Colors";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DateUtils from "../../Utils/DateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoginForm from "../../pages/login/LoginForm";
import ModalComponent from "./ModalComponent";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import dayjs from "dayjs";
import leftarrow from "./left-arrow.svg";
import moment from "moment";
import rightarrow from "./right-arrow.svg";
import { useState } from "react";

export const Timings = [
  { name: "6:00-7:00 AM", disabled: false },
  { name: "7:00-8:00 AM", disabled: false },
  { name: "8:00-9:00 AM", disabled: false },
  { name: "9:00-10:00 AM", disabled: false },
  { name: "10:00-11:00 AM", disabled: false },
  { name: "11:00-12:00 PM", disabled: false },
  { name: "12:00-1:00 PM", disabled: false },
  { name: "1:00-2:00 PM", disabled: false },
  { name: "2:00-3:00 PM", disabled: false },
  { name: "3:00-4:00 PM", disabled: false },
  { name: "4:00-5:00 PM", disabled: false },
  { name: "5:00-6:00 PM", disabled: false },
  { name: "6:00-7:00 PM", disabled: false },
  { name: "7:00-8:00 PM", disabled: false },
  { name: "8:00-9:00 PM", disabled: false },
  { name: "9:00-10:00 PM", disabled: false },
  { name: "10:00-11:00 PM", disabled: false },
  { name: "11:00-12:00 AM", disabled: false },
  { name: "12:00-1:00 AM", disabled: false },
  { name: "1:00-2:00 AM", disabled: false },
];

interface CustomDateCalendarProps {
  tableData?: any;
  setTableData?: any;
  selctedname?: any;
  type?: any;
  selectedService?: any;
  setIsBackButtonVisible?: any;
  setValidationError?: any;
  numberOfPersons?: any;
  setNumberOfPersons?: any;
  handleAddmore?: any;
  handleRemoveItem?: any;
}

interface datatype {
  startTime: number;
  endTime: number;
  type: String;
}

interface Range {
  start: number;
  end: number;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: Colors.BUTTON_COLOR,
    color: "black",
  },
}));
const DeleteBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "white",
    color: "red",
  },
}));

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

export default function CustomDateCalendar({
  tableData,
  setTableData,
  selctedname,
  type,
  selectedService,
  setIsBackButtonVisible,
  setValidationError,
  numberOfPersons,
  setNumberOfPersons,
  handleAddmore,
  handleRemoveItem,
}: CustomDateCalendarProps) {
  const [selectedDate, setSelectedDate] = React.useState<string>(
    dayjs().format("YYYY-MM-DD")
  ); // Default to today's date

  const [calendarDate, setCalendarDate] = React.useState<string>(
    dayjs().format("YYYY-MM-DD")
  );

  const user = localStorage.getItem("user");
  const userData = user && JSON.parse(user);

  const [calendarKey, setCalendarKey] = React.useState<string>(
    Date.now().toString()
  );

  const [isChooseModalOpen, setChooseIsModalOpen] = useState(false);
  const [isChoose, setIsChoose] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const CustomDateHeader = (props: any) => {
    const { currentMonth, onMonthChange } = props;

    const handleMonthChange = (amount: number) => {
      const newMonth = currentMonth.add(amount, "month");
      onMonthChange(newMonth);
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Montserrat, sans-serif !important",
        }}
      >
        <IconButton
          style={{
            border: "1px solid var(--Gray-Light, #DDD)",
            borderRadius: "12px",
          }}
          onClick={() => handleMonthChange(-1)}
        >
          <img src={leftarrow} alt="" />
        </IconButton>
        <Typography
          fontWeight={700}
          fontSize={"18px !important"}
          fontFamily={"Montserrat, sans-serif !important"}
        >
          {currentMonth ? currentMonth.format("MMMM") : ""}
        </Typography>
        <IconButton
          style={{
            border: "1px solid var(--Gray-Light, #DDD)",
            borderRadius: "12px",
          }}
          onClick={() => handleMonthChange(1)}
        >
          <img src={rightarrow} alt="" />
        </IconButton>
      </div>
    );
  };

  const [selectedTimings, setSelectedTimings] = React.useState<string[]>([]);
  const navigate = useNavigate();

  const [disableData, setDisableData] = React.useState<datatype[]>([]);
  const [items, setItems] = useState([
    { name: "6:00-7:00 AM", disabled: false },
    { name: "7:00-8:00 AM", disabled: false },
    { name: "8:00-9:00 AM", disabled: false },
    { name: "9:00-10:00 AM", disabled: false },
    { name: "10:00-11:00 AM", disabled: false },
    { name: "11:00-12:00 PM", disabled: false },
    { name: "12:00-1:00 PM", disabled: false },
    { name: "1:00-2:00 PM", disabled: false },
    { name: "2:00-3:00 PM", disabled: false },
    { name: "3:00-4:00 PM", disabled: false },
    { name: "4:00-5:00 PM", disabled: false },
    { name: "5:00-6:00 PM", disabled: false },
    { name: "6:00-7:00 PM", disabled: false },
    { name: "7:00-8:00 PM", disabled: false },
    { name: "8:00-9:00 PM", disabled: false },
    { name: "9:00-10:00 PM", disabled: false },
    { name: "10:00-11:00 PM", disabled: false },
    { name: "11:00-12:00 AM", disabled: false },
    { name: "12:00-1:00 AM", disabled: false },
    { name: "1:00-2:00 AM", disabled: false },
  ]);

  const handleDateSelection = (newValue: any) => {
    let datedata = newValue.$d;
    const parsedDate = moment(datedata);
    const formattedDate = parsedDate.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setCalendarDate(formattedDate);
    ApiCall(formattedDate);
    console.log(formattedDate, "formattedDate1234");
    setDrawerOpen(false);
  };

  const [openLgin, setOpenLogin] = useState(false);

  const hanldeLoginWithoutClose = () => {
    setIsLogin(false);
    setOpenLogin(true);
  };

  const ApiCall = async (dateValue: any) => {
    if (type === BookingType.BowlingMachine) {
      selectedService = "Bowling Machine";
    }
    if (type === BookingType.CricketNet) {
      selectedService = "Cricket Net";
    }
    if (dateValue && type && selectedService) {
      try {
        const response = await BookingApi.filter({
          startDate: dateValue,
          type: type,
          endDate: dateValue,
          court:
            type === BookingType.BowlingMachine ||
            type === BookingType.CricketNet
              ? "1"
              : BookingSubTypes[
                  selectedService as keyof typeof BookingSubTypes
                ].toString(),
        });
        setDisableData(response);
      } catch (error: any) {
        console.error("Error:", error.message);
      }
    }
  };

  // showing initially selected date disabled items

  React.useEffect(() => {
    if (selectedService && type) {
      const todayDate = moment().format("YYYY-MM-DD"); // Get today's date
      setSelectedDate(todayDate);
      setCalendarDate(todayDate);
      ApiCall(todayDate); // Call the API with today's date
      console.log(disableData, todayDate, "today disabled items");
    }
  }, [selectedService, type]);

  function formatTimeRange(timeRange: {
    startTime: string | number | Date;
    endTime: string | number | Date;
  }) {
    // Function to format time in "HH:MM AM/PM" format
    const formatTime = (date: Date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${minutesFormatted} ${ampm}`;
    };

    // Convert timestamps to Date objects
    const startDate = new Date(timeRange.startTime);
    const endDate = new Date(timeRange.endTime);

    // Format both start and end times
    const formattedStartTime = formatTime(startDate).split(" ");

    const formattedEndTime = formatTime(endDate);

    // Return in the format "from - to"
    return `${formattedStartTime[0]}-${formattedEndTime}`;
  }

  // Format an array of time ranges
  function formatTimeRanges(timeRanges: any[]) {
    return timeRanges.map((range) => ({
      type: range.type,
      formattedTime: formatTimeRange(range),
    }));
  }
  const parse12HourTo24 = (time: string, modifier: string): number => {
    let [hour, minute] = time.split(":").map(Number);
    if (modifier === "PM" && hour < 12) {
      hour += 12;
    }
    if (modifier === "AM" && hour === 12) {
      hour = 0; // Midnight
    }
    return hour;
  };

  const splitTimeAndModifier = (timeRange: string) => {
    // Split the range on the dash
    let [fromTimeVal, toTimeWithModifier] = timeRange
      .split("-")
      .map((str) => str.trim());

    // Further split "from" and "to" times by space to separate time and AM/PM
    let [fromTime, fromModifier] = fromTimeVal.split(" ");
    let [toTime, toModifier] = toTimeWithModifier.split(" ");

    return {
      fromTime,
      fromModifier,
      toTime,
      toModifier,
    };
  };

  const getTimeValueFromMarks = (timeStr: string) => {
    // Parse the "from" time and "to" time into 24-hour format
    const splitTime1 = timeStr.split(" ");
    const splitTime2 = splitTime1[0].split("-");
    let timeValue = "";
    if (splitTime1[1] === "AM" && splitTime2[1] === "12:00") {
      timeValue = `${splitTime2[0]} PM - ${splitTime2[1]} ${splitTime1[1]}`;
    } else if (splitTime1[1] === "PM" && splitTime2[1] === "12:00") {
      timeValue = `${splitTime2[0]} AM - ${splitTime2[1]} ${splitTime1[1]}`;
    } else {
      timeValue = `${splitTime2[0]} ${splitTime1[1]} - ${splitTime2[1]} ${splitTime1[1]}`;
    }
    const splitmodi = splitTimeAndModifier(timeValue);
    let fromHour = parse12HourTo24(splitmodi.fromTime, splitmodi.fromModifier);
    let toHour = parse12HourTo24(splitmodi.toTime, splitmodi.toModifier);
    if (splitmodi.fromTime === "1:00" && splitmodi.fromModifier === "AM") {
      fromHour = 1;
    }
    if (splitmodi.fromTime === "12:00" && splitmodi.fromModifier === "AM") {
      fromHour = 24;
    }
    if (splitmodi.toTime === "1:00" && splitmodi.toModifier === "AM") {
      toHour = 1;
    }
    if (splitmodi.toTime === "12:00" && splitmodi.toModifier === "AM") {
      toHour = 24;
    }

    const fromValue = fromHour;
    const toValue = toHour;
    return { fromValue, toValue };
  };
  const MilisecondsToHours = () => {
    let combinedIntervals: any[] = [];
    let disData: any[] = [];
    disableData.forEach((item) => {
      disData.push({ startTime: item.startTime, endTime: item.endTime });
      const start = item.startTime;
      const end = item.endTime;
      let fromTime = moment(start);
      let toTime = moment(end);
      let duration = moment.duration(toTime.diff(fromTime));
      let diff = duration.hours();
      let array = [];

      for (let i = 0; diff > i; i++) {
        let result = moment(fromTime).add(i, "hours").format("h:mm");
        array.push(
          result +
            "-" +
            moment(fromTime)
              .add(i + 1, "hours")
              .format("h:mm A")
        );
      }
      combinedIntervals = combinedIntervals.concat(array);
    });
    if (type === BookingType.CricketNet) {
      selectedService = "Cricket Net";
    }
    if (type === BookingType.BowlingMachine) {
      selectedService = "Bowling Machine";
    }
    let isBookingExists: any = [];
    switch (selectedService) {
      case "PS 1 & 2":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "PS 1" ||
              booking.name === "PS 2" ||
              booking.name === "PS 1 & 2")
          );
        });
        break;
      case "PS 1":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "PS 1" || booking.name === "PS 1 & 2")
          );
        });
        break;
      case "PS 2":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "PS 2" || booking.name === "PS 1 & 2")
          );
        });
        break;
      case "Turf 2.O & 3.O":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "Turf 2.O" ||
              booking.name === "Turf 3.O" ||
              booking.name === "Turf 2.O & 3.O")
          );
        });
        break;
      case "Turf 2.O":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "Turf 2.O" || booking.name === "Turf 2.O & 3.O")
          );
        });
        break;
      case "Turf 3.O":
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.type === type &&
            (booking.name === "Turf 3.O" || booking.name === "Turf 2.O & 3.O")
          );
        });
        break;
      default:
        isBookingExists = tableData.filter((booking: any) => {
          return (
            booking.date === selectedDate &&
            booking.name === selectedService &&
            booking.type === type
          );
        });
    }
    isBookingExists.map((items: any) => combinedIntervals.push(...items.time));
    const formatValue = formatTimeRanges(disableData);
    formatValue.map((dataVal) => {
      combinedIntervals.push(dataVal.formattedTime);
    });
    const uniqueArray = combinedIntervals.reduce(
      (accumulator, currentValue) => {
        if (!accumulator.includes(currentValue)) {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );
    setOccupiedRanges([]);
    updateOccupiedTimes([], []);
    if (uniqueArray.length > 0 && selectedDate !== "") {
      let updatedItems = items.map((item) =>
        uniqueArray.includes(item.name)
          ? { ...item, disabled: true }
          : { ...item, disabled: false }
      );
      setItems(updatedItems);

      const occupied: Range[] = [];

      uniqueArray.map((itemVal: string) => {
        const a = getTimeValueFromMarks(itemVal);
        // const b = parseTime(itemVal);
        if (a.fromValue && a.toValue)
          occupied.push({
            start: a.fromValue,
            end: a.toValue,
          });
      });
      occupied.sort((a, b) => a.start - b.start);

      setOccupiedRanges(occupied);
      updateOccupiedTimes([], occupied);
    } else {
      setItems(Timings);
    }
  };

  React.useEffect(() => {
    if (disableData || selectedDate !== "") {
      MilisecondsToHours();
      console.log(disableData, "mileSeconds1234");
    } else {
      setOccupiedRanges([]);
      updateOccupiedTimes([], []);
    }
  }, [disableData, selectedDate]);

  React.useEffect(() => {
    // On initial load, make the API call for today's date
    const today = dayjs().format("YYYY-MM-DD");
    ApiCall(today);
  }, []);

  console.log(disableData, "disableData12345");

  const handleTimeSelection = (time: string) => {
    const parseTime = (timeStr: string) => {
      let [hour, modifier] = timeStr.split(" ");
      let hourInt = parseInt(hour);

      if (modifier === "PM" && hourInt < 12) {
        hourInt += 12;
      }
      if (modifier === "AM" && hourInt === 12) {
        hourInt = 0; // Midnight edge case
      }

      return hourInt;
    };

    const [start, end] = time.split("-");
    const startHour = parseTime(start.trim());
    const endHour = parseTime(end.trim());

    const isRangeValid = (start: number, end: number) => {
      const isBlocked = blockedRanges.some(
        (r) => start < r.end && end > r.start
      );
      const isOccupied = occupiedRanges.some(
        (r) => start < r.end && end > r.start
      );

      return !isBlocked && !isOccupied;
    };

    // if (isRangeValid(startHour, endHour)) {
    setSelectedTimings((prevSelectedTimings) => {
      if (prevSelectedTimings.includes(time)) {
        return prevSelectedTimings.filter(
          (selectedTime) => selectedTime !== time
        );
      } else {
        return [...prevSelectedTimings, time];
      }
    });
    // setSliderValue([startHour, endHour]);

    // setTimeDraw(false);
    // } else {
    //   alert("Selected time range overlaps with blocked or occupied time.");
    // }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const mergeTimeSlots = (timeSlots: string[]): string => {
  //   if (timeSlots.length === 0) return "";

  //   const timeRanges: string[] = [];
  //   let [startTime] = timeSlots[0].split("-");
  //   let lastEndTime = timeSlots[0].split("-")[1];

  //   for (let i = 1; i < timeSlots.length; i++) {
  //     const [nextStartTime, nextEndTime] = timeSlots[i].split("-");

  //     // If the current end time is the same as the next start time, merge the ranges
  //     if (lastEndTime.trim() === nextStartTime.trim()) {
  //       lastEndTime = nextEndTime; // Extend the range
  //     } else {
  //       // Push the current range to the array and reset start/end times
  //       timeRanges.push(`${startTime.trim()} - ${lastEndTime.trim()}`);
  //       startTime = nextStartTime;
  //       lastEndTime = nextEndTime;
  //     }
  //   }

  //   // Push the final range to the array
  //   timeRanges.push(`${startTime.trim()} - ${lastEndTime.trim()}`);

  //   return timeRanges.join(", ");
  // };

  function areIndexesContinuous(indexes: string | any[]) {
    if (indexes.length === 0) return false;

    for (let i = 1; i < indexes.length; i++) {
      if (indexes[i] !== indexes[i - 1] + 1) {
        return false;
      }
    }

    return true;
  }

  const handleAddButtonClick = async (
    type: string,
    selectedService: string
  ) => {
    if (type === BookingType.Badminton && numberOfPersons === "") {
      setIsModalOpen(true);
      setValidationError("Please enter a valid number of persons (1 to 10).");

      return;
    }

    if (userData && userData.userType === "user") {
      if (selectedDate !== "" && selectedTimings.length > 0) {
        const indexes = selectedTimings.map((slot: string) =>
          Timings.findIndex((timing) => timing.name === slot)
        );
        indexes.sort((a: number, b: number) => a - b);

        const timeList = Timings;
        const booValue: string[] = [];
        indexes.map((index: any) => {
          booValue.push(timeList[index].name);
        });

        const totalDuration = selectedTimings.length;
        let ratePerHour = 0;
        let finalTime = selectedTimings;
        if (areIndexesContinuous(indexes)) {
          console.log(booValue, "booValue");
          const firstIndex = 0;
          const lastIndex = booValue.length - 1;
          console.log(booValue[firstIndex], "firstIndex");
          console.log(booValue[lastIndex], "lastIndex");
          const firstTime = booValue[firstIndex].split("-")[0];
          const lastTime = booValue[lastIndex].split("-")[1];
          finalTime = [`${firstTime}-${lastTime}`];
        } else {
          finalTime = selectedTimings.map((time) => {
            const [start, end] = time.split("-");
            return `${start}-${end}`;
          });
        }

        const bookings = {
          type,
          name: selectedService,
          date: selectedDate,
          time: finalTime,
          amount: 0,
          duration: totalDuration,
          numberOfPersons: 0,
          // ... other properties
        };

        try {
          const courtValue =
            type === BookingType.BowlingMachine ||
            type === BookingType.CricketNet
              ? 1
              : BookingSubTypes[bookings.name as keyof typeof BookingSubTypes];
              const condition = type === BookingType.Turf  && courtValue === 3
              ? "Turf 2.o & 3.o" : bookings.type;
          const response = await BookingApi.getBookingAmount(condition);
          if (response) {
            ratePerHour = response.bookingAmount;
            if (type === BookingType.Badminton && numberOfPersons !== "") {
              const totalAmt = totalDuration * ratePerHour;

              bookings.amount = totalAmt * parseInt(numberOfPersons);
              bookings.numberOfPersons = parseInt(numberOfPersons);
            } else if (
              type === BookingType.Playstaion &&
              courtValue === 3
            ) {
              const totalAmt = totalDuration * ratePerHour;
              bookings.amount = totalAmt * 2;
            } else {
              bookings.amount = totalDuration * ratePerHour;
            }
          } else {
            console.log("amount fetch Failed");
          }
        } catch (err) {
          console.log("err", err);
        }

        let flag = false;
        for (const timeData of selectedTimings) {
          try {
            const newCourt =
              type === BookingType.BowlingMachine ||
              type === BookingType.CricketNet
                ? 1
                : BookingSubTypes[
                    bookings.name as keyof typeof BookingSubTypes
                  ];

            const startDateTime = DateUtils.startTimeAddtoDate(timeData);
            const endDateTime = DateUtils.endTimeAddtoDate(timeData);
            const endMilli = DateUtils.joinDate(
              DateUtils.formatDate(new Date(selectedDate), "YYYY-MM-DD"),
              endDateTime
            );
            const startMilli = DateUtils.joinDate(
              DateUtils.formatDate(new Date(selectedDate), "YYYY-MM-DD"),
              startDateTime
            );

            const startMilliSec = moment(
              startMilli,
              "YYYY-MM-DD hh:mm:ss A"
            ).valueOf();
            const endMilliSec = moment(
              endMilli,
              "YYYY-MM-DD hh:mm:ss A"
            ).valueOf();
            await BookingApi.getBookedList({
              type: type,
              bookingtype: "online",
              startTime: startMilliSec,
              endTime: endMilliSec,
              user: userData.id,
              startDate: DateUtils.formatDate(
                new Date(selectedDate),
                "YYYY-MM-DD"
              ),
              endDate: DateUtils.formatDate(
                new Date(selectedDate),
                "YYYY-MM-DD"
              ),
              court: newCourt.toString(),
              userBookingType: "online",
            });
          } catch (error: any) {
            flag = true;
            if (error.message === "Please choose another date and slot") {
              setIsChoose(true);
            }
          }
        }
        if (
          !tableData.some(
            (el: {
              type: string;
              name: string;
              date: string;
              time: string[];
              amount: number;
              duration: number;
            }) => el === bookings
          )
        ) {
          if (bookings.type === BookingType.BowlingMachine) {
            bookings.name = "Bowling Machine";
          }
          if (bookings.type === BookingType.CricketNet) {
            bookings.name = "Cricket Net";
          }
          if (!flag) {
            setTableData((prevTableData: any) => [...prevTableData, bookings]);
          }
        }
        const occupied: React.SetStateAction<Range[]> = [...occupiedRanges];
        bookings.time.map((itemVal: string) => {
          const a = getTimeValueFromMarks(itemVal);
          // const b = parseTime(itemVal);
          if (a.fromValue && a.toValue)
            occupied.push({
              start: a.fromValue,
              end: a.toValue,
            });
        });
        occupied.sort((a, b) => a.start - b.start);

        setOccupiedRanges(occupied);
        updateOccupiedTimes([], occupied);
        // Reset selected date and timings
        // setSelectedDate("");
        setSelectedTimings([]);
        setCalendarKey(Date.now().toString());
        setNumberOfPersons("");
        setTimeDraw(false);
      } else {
        setChooseIsModalOpen(true);
      }
    } else {
      setIsLogin(true);
    }
  };

  const location = useLocation();

  React.useEffect(() => {
    if (tableData && tableData.length === 0) {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [tableData]);

  React.useEffect(() => {
    // Check if the current route is "/service-booking"
    if (location.pathname === "/service-booking") {
      setIsBackButtonVisible(false);
    } else {
      // Reset the state for other routes
      setIsBackButtonVisible(true);
    }
  }, [location]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const getCurrentWeek = (date: string) => {
    return Array.from({ length: 5 }, (_, i) =>
      dayjs(date).startOf("day").add(i, "day")
    );
  };

  const currentWeek = getCurrentWeek(calendarDate);

  const handleDateClick = (date: any) => {
    setOccupiedRanges([]);
    updateOccupiedTimes([], []);
    let datedata = date.$d;
    const parsedDate = moment(datedata);
    const formattedDate = parsedDate.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    ApiCall(formattedDate);
    console.log(disableData, "mileSeconds1234");
  };

  const [timeDraw, setTimeDraw] = useState(false);

  const toggleDrawer = () => {
    setTimeDraw(true);
  };

  const toggleDrawerClose = () => {
    setTimeDraw(false);
    setSelectedTimings([]);
  };

  const [sliderValue, setSliderValue] = useState<number[] | null>(null); // No initial range
  const [blockedRanges, setBlockedRanges] = useState<Range[]>([]);
  const [occupiedRanges, setOccupiedRanges] = useState<Range[]>([]);

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const maxSliderValue = isXs || isSm ? 400 : isMd ? 300 : 320;

  const [scrollValue, setScrollValue] = React.useState(160);
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const handleScrollChange = (event: any, newValue: any) => {
    setScrollValue(newValue);
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = newValue;
    }
  };

  const updateOccupiedTimes = (blocked: Range[], occupied: Range[]) => {
    // Map the items with updated disabled state based on occupied ranges
    setItems((prevItems) =>
      prevItems.map((item, index) => {
        // Convert index to hour range
        const startHour = index + 6; // Assuming items start from 6 AM
        const endHour = startHour + 1; // Each item represents 1-hour slot

        // Check if the slot is within any occupied range
        const isOccupied = occupied.some(
          (range) => startHour < range.end && endHour > range.start
        );

        return { ...item, disabled: isOccupied };
      })
    );
  };

  const marks = [
    // { value: 0, label: "12 AM" },
    { value: 1, label: "1 AM" },
    { value: 2, label: "2 AM" },
    { value: 3, label: "3 AM" },
    { value: 4, label: "4 AM" },
    { value: 5, label: "5 AM" },
    { value: 6, label: "6 AM" },
    { value: 7, label: "7 AM" },
    { value: 8, label: "8 AM" },
    { value: 9, label: "9 AM" },
    { value: 10, label: "10 AM" },
    { value: 11, label: "11 AM" },
    { value: 12, label: "12 PM" },
    { value: 13, label: "1 PM" },
    { value: 14, label: "2 PM" },
    { value: 15, label: "3 PM" },
    { value: 16, label: "4 PM" },
    { value: 17, label: "5 PM" },
    { value: 18, label: "6 PM" },
    { value: 19, label: "7 PM" },
    { value: 20, label: "8 PM" },
    { value: 21, label: "9 PM" },
    { value: 22, label: "10 PM" },
    { value: 23, label: "11 PM" },
    { value: 24, label: "12 AM" },
  ];

  const getTrackBackground = () => {
    // console.log("Occupied Ranges12345: ", occupiedRanges); // Check if the ranges are updated

    let gradientParts: string[] = [];
    let currentPos = 0;

    // Function to add a range to the gradient parts
    const addRange = (start: number, end: number, color: string) => {
      if (start > currentPos) {
        gradientParts.push(`green ${(currentPos / 24) * 100}%`);
        gradientParts.push(`green ${(start / 24) * 100}%`);
      }
      gradientParts.push(`${color} ${(start / 24) * 100}%`);
      gradientParts.push(`${color} ${(end / 24) * 100}%`);
      currentPos = end;
    };

    // Add the selected range to the occupied ranges
    console.log("sliderValue", sliderValue);
    if (sliderValue) {
      addRange(sliderValue[0], sliderValue[1], "red"); // Change color if needed
    }

    // Add the occupied ranges to the gradient
    occupiedRanges.forEach((range) => addRange(range.start, range.end, "red"));

    // Add the remaining available part if there is space left
    if (currentPos < 24) {
      gradientParts.push(`green ${(currentPos / 24) * 100}%`);
      gradientParts.push(`green 100%`);
    }

    return `linear-gradient(to right, ${gradientParts.join(", ")})`;
  };
  React.useEffect(() => {
    // Trigger any necessary updates when occupiedRanges changes
  }, [occupiedRanges]);
  const isRangeValid = (range: number[]) => {
    const [start, end] = range;
    // const isBlocked = blockedRanges.some((r) => start < r.end && end > r.start);
    const isOccupied = occupiedRanges.some(
      (r) => start < r.end && end > r.start
    );

    return !isOccupied;
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      if (isRangeValid(newValue)) {
        setSliderValue(newValue);
      } else {
        alert("Selected range overlaps with  occupied time.");
      }
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const totalAmount = tableData.reduce(
    (acc: any, booking: any) => acc + booking.amount,
    0
  );

  const getDayName = (dateString: any) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };
  const hasItems = tableData.length > 0;

  const handleRemoveItemData = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
    localStorage.setItem("bookings", JSON.stringify(updatedTableData));
    localStorage.removeItem("selectedService");
    const occupied: React.SetStateAction<Range[]> = [...occupiedRanges];
    const val: { start: number; end: number }[] = [];
    tableData[index].time.map((itemVal: string) => {
      const a = getTimeValueFromMarks(itemVal);
      if (a.fromValue && a.toValue)
        val.push({
          start: a.fromValue,
          end: a.toValue,
        });
    });
    // occupied.sort((a, b) => a.start - b.start);

    const filteredOccupied = occupied.filter((item) => {
      // Check if the current range matches any range in rangesToRemove
      return !val.some(
        (range: { start: number; end: number }) =>
          range.start === item.start && range.end === item.end
      );
    });
    setOccupiedRanges(filteredOccupied);
    updateOccupiedTimes([], filteredOccupied);
  };

  console.log(selectedService, "service selected 32534654869");
  console.log(tableData, "bookings items");

  return (
    <>
      <Stack
        padding={{
          xs: " 0px",
          sm: "2rem 0px",
          md: "2rem 0px",
          lg: "0px 20px",
        }}
        display="flex"
        flexDirection="column"
        spacing={2}
        maxWidth={1146}
        alignItems={{ xs: "center", sm: "center", md: "center", lg: "center" }}
        width="100%"
        margin="0 auto"
      >
        <Box width="100%">
          <Typography
            marginTop={1}
            marginBottom={1}
            fontWeight={600}
            textAlign={"start"}
            width={"100%"}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
          >
            Please Select Date :
          </Typography>
          <Box
            pt={"15px"}
            maxWidth={600}
            margin={"auto"}
            justifyContent={"space-between"}
            display="flex"
            alignItems="center"
          >
            {currentWeek.map((date) => (
              <Box
                width={70}
                key={date.format("DD-MM-YYYY")}
                sx={{
                  borderRadius: "12px",
                  padding: "5px",
                  margin: "0 5px",
                  border: date.isSame(dayjs(selectedDate), "day")
                    ? "1px solid #15B5FC"
                    : "1px solid #ccc",
                  background: date.isSame(dayjs(selectedDate), "day")
                    ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                    : "rgba(0, 0, 0, 0.1)",
                  color: date.isSame(dayjs(selectedDate), "day")
                    ? "black"
                    : "#000",
                  cursor: "pointer",
                  "&:hover": {
                    border: `1px solid ${Colors.BUTTON_COLOR}`,
                    color: date.isSame(dayjs(selectedDate), "day")
                      ? "#FFF"
                      : Colors.BUTTON_COLOR,
                  },
                }}
                onClick={() => handleDateClick(date)}
              >
                <Typography
                  fontWeight={600}
                  fontFamily={"Montserrat, sans-serif"}
                  textAlign="center"
                  color={
                    date.isSame(dayjs(selectedDate), "day") ? "black" : "black"
                  }
                  fontSize={"14px"}
                >
                  {date.format("MMM")}
                </Typography>
                <Typography
                  fontWeight={700}
                  fontFamily={"Montserrat, sans-serif"}
                  textAlign="center"
                  color={
                    date.isSame(dayjs(selectedDate), "day") ? "black" : "#000"
                  }
                  fontSize={"14px"}
                >
                  {date.format("DD")}
                </Typography>
                <Typography
                  fontWeight={600}
                  fontFamily={"Montserrat, sans-serif"}
                  textAlign="center"
                  color={
                    date.isSame(dayjs(selectedDate), "day") ? "black" : "#000"
                  }
                  fontSize={"14px"}
                >
                  {date.format("ddd")}
                </Typography>
              </Box>
            ))}
            <IconButton
              sx={{
                border: `2px solid ${Colors.BUTTON_COLOR}`,
                backgroundColor: Colors.BUTTON_COLOR,
                color: "black",
                borderRadius: "50%",
                padding: "8px",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: Colors.BUTTON_COLOR,
                  color: "white",
                },
                height: 40,
                width: 40,
              }}
              onClick={() => setDrawerOpen(true)}
            >
              <CalendarTodayIcon
                style={{
                  color: "black",
                }}
              />
            </IconButton>
          </Box>

          <Drawer
            PaperProps={{
              sx: {
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              },
            }}
            anchor="bottom"
            open={drawerOpen}
          >
            <Box display={"flex"} justifyContent={"end"}>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <CloseIcon style={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box
              sx={{ padding: 2 }}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={"10px"}
            >
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"18px"}
                fontWeight={700}
              >
                Please Select Date
              </Typography>
              <Divider
                orientation="horizontal"
                sx={{
                  width: { xs: "50%", sm: "50%", md: "10%" },
                  backgroundColor: Colors.BUTTON_COLOR,
                  height: "2px",
                  marginBottom: 2,
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  key={calendarKey}
                  components={{ CalendarHeader: CustomDateHeader }}
                  sx={{
                    border: "0.25px solid var(--black, #000)",
                    borderRadius: "12px",
                    height: "280px",
                    margin: "0px",
                    fontWeight: 700,
                    padding: 2,
                    opacity: 1,
                    fontFamily: "Montserrat, sans-serif !important",
                    "& .MuiPickersDay-root.Mui-selected": {
                      color: "white !important",
                      background: "#15B5FC !important",
                      border: "1px solid #15B5FC !important",
                      borderRadius: "12px",
                      fontSize: "18px",
                      fontFamily: "Montserrat, sans-serif",
                    },
                    "& .MuiPickersDay-today": {
                      border: "1px solid #15B5FC !important",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "18px",
                      borderRadius: "12px",
                      background: "white !important",
                      color: "#15B5FC !important",
                    },
                    "& .MuiPickersDay-root": {
                      fontFamily: "Montserrat, sans-serif !important",
                      fontWeight: 700,
                      fontSize: "18px",
                    },
                    "& .MuiDayCalendar-weekDayLabel": {
                      fontFamily: "Montserrat, sans-serif !important",
                      fontWeight: 700,
                      fontSize: "18px",
                    },
                  }}
                  onChange={(date) => handleDateSelection(date)}
                  disablePast
                />
              </LocalizationProvider>
            </Box>
          </Drawer>
        </Box>

        {(type === BookingType.BowlingMachine ||
          type === BookingType.CricketNet ||
          selectedService) && (
          <Typography
            marginTop={1}
            fontWeight={600}
            textAlign={"start"}
            width={"100%"}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
          >
            Slide to check availability:
          </Typography>
        )}

        {(type === BookingType.BowlingMachine ||
          type === BookingType.CricketNet ||
          selectedService) && (
          <>
            <Box
              margin={"0px !important"}
              ref={sliderRef}
              sx={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                padding: "10px 0",
                scrollbarWidth: "none",
                maxWidth: "100%", // Ensure the container doesn't exceed the viewport width
                width: "1000px",
              }}
            >
              <Box>
                <Slider
                  value={sliderValue || [0, 24]}
                  min={0}
                  max={24}
                  step={1}
                  marks={marks} // Using customized marks
                  onChange={handleChange}
                  valueLabelDisplay="off" // Display labels only when dragging
                  disabled
                  sx={{
                    width: "1100px !important",
                    // maxWidth: "100%",
                    fontWeight: "600",
                    "& .MuiSlider-rail": {
                      height: 10,
                      opacity: 1,
                      background: getTrackBackground(),
                    },
                    "& .MuiSlider-track": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiSlider-thumb": {
                      display: "none",
                    },
                    "& .MuiSlider-valueLabel": {
                      transform: "translateY(20px) rotate(45deg)", // Rotate labels
                      whiteSpace: "nowrap",
                    },
                    "@media (max-width: 600px)": {
                      width: "400px",
                      "& .MuiSlider-rail": {
                        height: 8,
                      },
                      "& .MuiSlider-valueLabel": {
                        fontSize: "0.75rem",
                        transform: "translateY(20px) rotate(45deg)", // Rotate labels on small screens
                      },
                    },
                    "& .MuiSlider-markLabel": {
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Prevent overflow
                      textOverflow: "ellipsis", // Show ellipsis if the text overflows
                      marginTop: "10px", // Add space between the labels and the slider
                      fontSize: "0.8rem",
                      fontWeight: 600, // Smaller font size for labels
                    },
                  }}
                />
              </Box>
            </Box>
            <Slider
              value={scrollValue}
              min={0}
              max={maxSliderValue}
              onChange={handleScrollChange}
              sx={{
                mt: 2,
                "& .MuiSlider-rail": {
                  height: 8,
                  background: "#d3d3d3",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#15B5FC",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#15B5FC",
                },
                display: { xs: "none", sm: "none", md: "block" },
              }}
            />

            <Typography
              textAlign={"center"}
              fontSize={"12px"}
              fontFamily={"Montserrat, sans-serif"}
              color="black"
            >
              ⚠️ Drag the slider left or right to check availability for
              different time slots.{" "}
            </Typography>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              margin={"auto"}
              gap={"10px"}
              fontFamily={"Montserrat, sans-serif"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                <CircleIcon
                  sx={{
                    fontSize: "12px",
                    color: "red",
                  }}
                  fontSize="small"
                />{" "}
                Not Available
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                <CircleIcon
                  sx={{
                    fontSize: "12px",
                    color: "green",
                  }}
                  fontSize="small"
                />{" "}
                Available
              </Box>
              {/* <Box display={"flex"} alignItems={"center"} gap={"5px"}>
      <CircleIcon
        sx={{
          fontSize: "12px",
          color: "black",
        }}
        fontSize="small"
      />{" "}
      Blocked
    </Box> */}
            </Box>
          </>
        )}

        {(type === BookingType.BowlingMachine ||
          type === BookingType.CricketNet ||
          selectedService) && (
          <Typography
            marginTop={1}
            fontWeight={600}
            textAlign={"start"}
            width={"100%"}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
          >
            Please Pick a Time Slots :
          </Typography>
        )}

        <Box
          maxWidth={600}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width="100%"
          // marginTop={"40px !important"}
        >
          {" "}
          {(type === BookingType.BowlingMachine ||
            type === BookingType.CricketNet ||
            selectedService) && (
            <Box
              display="flex"
              gap={"20px"}
              flexDirection={"row"}
              // flexWrap={"wrap"}
              width={"100%"}
              alignItems={"center"}
            >
              <Box
                width={"100%"}
                display="flex"
                gap={"20px"}
                flexDirection={"column"}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                    width: "100%",
                    fontWeight: 700,
                    color: "black",
                  }}
                  variant="contained"
                  onClick={toggleDrawer}
                >
                  Time Slots
                </Button>
              </Box>

              <IconButton
                onClick={handleOpen}
                sx={{
                  border: `2px solid ${Colors.BUTTON_COLOR}`,
                  // display: hasItems ? "block" : "none",
                  color: Colors.BLACK,
                  borderRadius: "50%",
                  padding: "8px",
                  transition: "background-color 0.3s ease",
                  animation: hasItems ? `${fadeInOut} 3s ease-out` : "none", // Apply animation conditionally
                  height: 60,
                  width: 60,
                  backgroundColor: Colors.BUTTON_COLOR,

                  "&:hover": {
                    backgroundColor: Colors.BUTTON_COLOR,
                    color: "black",
                  },
                }}
              >
                <StyledBadge badgeContent={tableData.length} color="secondary">
                  <ShoppingCartIcon
                    style={{
                      background: Colors.BUTTON_COLOR,
                      color: "black",
                      fontSize: "40px",
                    }}
                  />
                </StyledBadge>
              </IconButton>
            </Box>
          )}
          <Box display="flex" gap={"10px"} justifyContent="start">
            <Modal open={open}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: 450,
                  bgcolor: "background.paper",
                  borderRadius: "12px",
                  boxShadow: 24,
                  width: { xs: "80%", sm: "80%", md: "80%", lg: "100%" },
                  p: "20px",
                }}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box>
                    <Typography
                      fontFamily={"Montserrat, sans-serif"}
                      variant="h6"
                      component="h2"
                    >
                      Your Bookings
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      sx={{
                        width: "40%",
                        backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                        height: "2px", // Adjust the height to make it more visible if needed
                      }}
                    />
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <IconButton onClick={handleClose}>
                      <CloseIcon
                        style={{
                          color: "black",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    maxHeight: 450,
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    paddingRight: "20px",
                  }}
                >
                  {tableData.length > 0 ? (
                    tableData.map((booking: any, index: any) => (
                      <>
                        <Typography
                          fontWeight={700}
                          fontFamily={"Montserrat, sans-serif"}
                          fontSize={"18px"}
                          textTransform={"capitalize"}
                          textAlign={"end"}
                        >
                          Bookings {index + 1}
                        </Typography>
                        <Box
                          key={index}
                          fontFamily={"Montserrat, sans-serif"}
                          sx={{ mb: 2 }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          borderBottom={"1px solid black"}
                          padding={"10px 0"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                          >
                            <Typography
                              fontWeight={500}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                              textTransform={"capitalize"}
                            >
                              {booking.type === BookingType.Turf ? (
                                <SportsSoccerIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : booking.type === BookingType.Playstaion ? (
                                <SportsEsportsIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : booking.type === BookingType.Badminton ? (
                                <SportsTennisIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : booking.type === BookingType.CricketNet ? (
                                <SportsCricketIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : booking.type ===
                                BookingType.BowlingMachine ? (
                                <SportsVolleyballIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : booking.type === BookingType.BoardGame ? (
                                <CasinoIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              ) : null}
                              {booking.type === ("turf" as BookingType)
                                ? "Turf / Football"
                                : booking.type}{" "}
                            </Typography>
                            <Typography
                              fontWeight={500}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                              textTransform={"capitalize"}
                            >
                              <DescriptionIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              {booking.name}
                            </Typography>
                            <Typography
                              fontWeight={900}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                              fontStyle={"italic"}
                            >
                              <CalendarTodayIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              {new Date(booking.date).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short", // 'Sep'
                                  day: "numeric", // '10'
                                }
                              )}
                              , ({getDayName(booking.date)}){" "}
                            </Typography>
                            <Typography
                              fontWeight={700}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                            >
                              <AccessTimeIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              {booking.time
                                .map((slot: string) => slot.replace("-", " - ")) // Add space before and after '-'
                                .join(", ")}
                            </Typography>
                            <Typography
                              fontWeight={500}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                            >
                              <CurrencyRupeeIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              {booking.amount}
                              {/* {booking.name === "Turf 2.O & 3.O"
                                ? booking.amount - 100
                                : booking.amount} */}
                            </Typography>
                            <Typography
                              fontWeight={500}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                            >
                              <AccessTimeIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              {booking.duration} hour
                            </Typography>

                            {booking.numberOfPersons ? (
                              <Typography
                                fontWeight={500}
                                fontFamily={"Montserrat, sans-serif"}
                                fontSize={"18px"}
                              >
                                <PersonIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    mr: 1,
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />{" "}
                                Number of Persons: {booking.numberOfPersons}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Box>

                          <IconButton
                            onClick={() => {
                              handleRemoveItemData(index);
                            }}
                          >
                            {/* <DeleteBadge badgeContent={index + 1}> */}
                            <DeleteIcon
                              style={{
                                color: "red",
                              }}
                            />
                            {/* </DeleteBadge> */}
                          </IconButton>
                        </Box>
                      </>
                    ))
                  ) : (
                    <Typography
                      fontWeight={700}
                      fontFamily={"Montserrat, sans-serif"}
                      fontSize={"18px"}
                      variant="body2"
                    >
                      No bookings available.
                    </Typography>
                  )}
                </Box>
                <Box
                  paddingTop={"20px "}
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                  gap={"5px !important"}
                >
                  <Typography
                    fontWeight={700}
                    fontFamily={"Montserrat, sans-serif"}
                    fontSize={"18px"}
                  >
                    Total Items: {tableData.length}
                  </Typography>
                  <Typography
                    fontWeight={700}
                    fontFamily={"Montserrat, sans-serif"}
                    fontSize={"18px"}
                  >
                    Total amount : {totalAmount}
                  </Typography>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Drawer
            PaperProps={{
              sx: {
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              },
            }}
            anchor="bottom"
            open={timeDraw}
          >
            <Box display={"flex"} justifyContent={"end"}>
              <IconButton onClick={toggleDrawerClose}>
                <CloseIcon
                  style={{
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                // width: 300,
                padding: 2,
              }}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={"10px"}
            >
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"18px"}
                fontWeight={700}
              >
                Please Select Time Slot
              </Typography>
              <Divider
                orientation="horizontal"
                sx={{
                  width: { xs: "50%", sm: "50%", md: "10%" },
                  backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                  height: "2px", // Adjust the height to make it more visible if needed
                  marginBottom: 2,
                }}
              />

              <Box>
                <Box
                  sx={{
                    maxWidth: 350,
                    width: "100%",
                    maxHeight: 400,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                  mb={2}
                  display="flex"
                  gap="2rem"
                  flexWrap="wrap"
                >
                  {items.map((item, index) => (
                    <Button
                      disabled={item.disabled}
                      sx={{
                        maxWidth: 147,
                        width: "100%",
                        height: "29px",
                        border: item.disabled
                          ? "1px solid #d63535"
                          : selectedTimings.includes(item.name)
                          ? "2px solid green"
                          : "1px solid green",
                        color: item.disabled
                          ? "gray"
                          : selectedTimings.includes(item.name)
                          ? "white"
                          : "green",
                        textAlign: "center",
                        padding: "4px 0px 5px 0px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "5px",
                        fontFamily: "Montserrat, sans-serif",
                        background: item.disabled
                          ? ""
                          : selectedTimings.includes(item.name)
                          ? "green"
                          : "none",
                        opacity: item.disabled ? 0.5 : 1,
                        ":hover": {
                          background: "green",
                          color: "white",
                        },
                      }}
                      key={index}
                      onClick={() => handleTimeSelection(item.name)}
                    >
                      <Box>
                        <Typography
                          fontFamily={"Montserrat, sans-serif"}
                          color={
                            item.disabled
                              ? "gray"
                              : selectedTimings.includes(item.name)
                              ? "white"
                              : "green"
                          }
                          sx={{
                            ":hover": {
                              color: "white",
                            },
                          }}
                          fontSize={{
                            xs: "14px",
                            sm: "14px",
                            md: "14px",
                            lg: "18px",
                          }}
                        >
                          {item.name.replace("-", " - ")}
                        </Typography>
                      </Box>
                    </Button>
                  ))}
                </Box>
                <Box
                  sx={{
                    maxWidth: 350,
                    width: "100%",
                  }}
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Button
                    fullWidth
                    onClick={() => handleAddButtonClick(type, selectedService)}
                    sx={{
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      textTransform: "none",
                      fontFamily: "Montserrat, sans-serif",
                      // width: "fit-content",
                      color: "white",
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Stack>
      <ModalComponent
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        text={"Number of persons cannot be empty. Please enter a value."}
        headingText={""}
      />

      <ModalComponent
        open={isChooseModalOpen}
        handleClose={() => setChooseIsModalOpen(false)}
        text={"Please choose date and slots"}
        headingText={""}
      />

      <ModalComponent
        open={isChoose}
        handleClose={() => setIsChoose(false)}
        text={"Please choose another date and slots"}
        headingText={""}
      />

      <ModalComponent
        open={isLogin}
        handleClose={hanldeLoginWithoutClose}
        text={"Could not add your bookings!\nPlease Login to Your Account"}
        headingText={""}
      />

      <LoginForm open={openLgin} handleClose={() => setOpenLogin(false)} />
    </>
  );
}
